/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Button, Col, Layout, Row } from 'antd';
import Container from '../../components/Container';

import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import SuggestionsTable from './SuggestionsTable';
import ApprovedTable from './ApprovedTable';
import ExecutingTable from './ExecutingTable';
import ImplementedTable from './ImplementedTable';

import './style.scss';

const { Header } = Layout;

const SuggestedRoot = ({ userId }) => {
  console.log('🚀 ~ SuggestedRoot ~ userId:', userId);
  const { t } = useTranslation('');
  const history = useHistory();
  const { typeTab } = useParams();

  return (
    <>
      <div className="instrument-wrapper instrument-block-wrap">
        <div className="instrument-inner-wrapper">
          <Header className="headerStyle">
            <Row className="overview-tabs-wrapper">
              <Col className="tabs-tab-wrapper" span={18}>
                <Button
                  type="text"
                  className={`overview-btn ${
                    typeTab === 'suggestions' ? 'ant-btn-active' : ''
                  }`}
                  onClick={() =>
                    history.push(
                      `/accounts/${userId}/suggested-portfolios/suggestions`
                    )
                  }
                >
                  {t('SUGGESTIONS')}
                </Button>
                <Button
                  type="text"
                  className={`overview-btn ${
                    typeTab === 'approved' ? 'ant-btn-active' : ''
                  }`}
                  onClick={() =>
                    history.push(
                      `/accounts/${userId}/suggested-portfolios/approved`
                    )
                  }
                >
                  {t('APPROVED')}
                </Button>
                <Button
                  type="text"
                  className={`overview-btn ${
                    typeTab === 'executing' ? 'ant-btn-active' : ''
                  }`}
                  onClick={() =>
                    history.push(
                      `/accounts/${userId}/suggested-portfolios/executing`
                    )
                  }
                >
                  {t('QUANTIFIED')}
                </Button>
                <Button
                  type="text"
                  className={`overview-btn ${
                    typeTab === 'implemented' ? 'ant-btn-active' : ''
                  }`}
                  onClick={() =>
                    history.push(
                      `/accounts/${userId}/suggested-portfolios/implemented`
                    )
                  }
                >
                  {t('IMPLEMENTED')}
                </Button>
              </Col>
            </Row>
          </Header>
            {typeTab === 'suggestions'
              ? <SuggestionsTable userId={userId}/>
              : typeTab === 'approved'
                ? <ApprovedTable userId={userId}/>
                : typeTab === 'executing'
                  ? <ExecutingTable userId={userId}/>
                  : typeTab === 'implemented'
                    ? <ImplementedTable userId={userId}/>
                    : null
            }
        </div>
      </div>
    </>
  );
};

export default SuggestedRoot;

SuggestedRoot.propTypes = {
  userId: PropTypes.string,
};
