import React from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Flex, Modal, Row, Spin } from 'antd';
import { setModelDefault } from '../../data/store/instruments/instrumentsActions';

const ModalActivateModel = ({modalActivateModel, handleActivateClose}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const { modelList, isLoading } = useSelector((state) => state.instrumentsReducer);
  // const [errorMessage, setErrorMessage ] = useState(false);

  const getModelById = () => {
    return modelList?.find(i => i.id === modalActivateModel)?.name;
  };

  // const handleErrorMessage = () => {
  //   setErrorMessage('Error! Cannot delete model');
  //   setTimeout(() => {
  //     setErrorMessage(false);
  //   }, 2500);
  // };

  const handleSubmit = () => {
    dispatch(setModelDefault({id: modalActivateModel, callback: handleActivateClose}));
  };

  return (
    <Modal
        width={650}
        className="modal-content-paper"
        open={modalActivateModel}
        onCancel={handleActivateClose}
        footer={null}
        zIndex={1600}>
        <Row justify="center">
          <Col className="modal-title">{t('CONFIRM_ACTIVATION')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col>
            <p className="modal-advisor-text">{t('MODAL_ACTIVATE_MODAL')}</p>
            <p className="modal-advisor-name">{getModelById()}</p>
          </Col>
        </Row>
        <Flex align="center" justify="center" style={{display: 'flex', width: '100%', height: '32px', marginTop: '24px'}}>
          {
            isLoading &&
              <Spin size="large" />
          }
        </Flex>
        <Row className="modal-advisor-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={handleActivateClose}
          >
            {t('CANCEL')}
          </Button>
          <Button
            disabled={isLoading} 
            className="modal-action-btn " 
            onClick={handleSubmit}>
            {t('MAKE_DEFAULT')}
          </Button>
        </Row>
      </Modal>
  );
};

export default ModalActivateModel;

ModalActivateModel.propTypes = {
  modalActivateModel: PropTypes.number,
  handleActivateClose: PropTypes.func,
};
