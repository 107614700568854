import { Button, Col, Progress, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPieData } from '../../../hooks/getPieData';
import { getAsseData } from '../../../hooks/getAsseData';
import { oneDecimalWithSpaces } from '../../../helpers/formatFractional';

const ClientAssets = ({selectedPortfolio, cashes, currencyList}) => {
  const [tabs , setTabs  ] = useState(0);
  const { t } = useTranslation('');

  const [pieData, setPieData] = useState(null);
  const [asseData, setAsseData] = useState(null);


  useEffect(() => {
    console.log('pieData', pieData);
  }, [pieData]);
  useEffect(() => {
    console.log('asseData', asseData);
  }, [asseData]);
  

  useEffect(() => {
      if (selectedPortfolio && cashes) {
        const data = getPieData(selectedPortfolio, cashes);
        setPieData(data);
      }
      if (selectedPortfolio && currencyList) {
        const assetData = getAsseData(selectedPortfolio, currencyList);
        setAsseData(assetData);
      }
    }, [selectedPortfolio, cashes, currencyList]);

  const handleTabs = () => {
    setTabs(tabs ? 0 : 1);
  };

  return (
    <div className='class-side-wrap'>
      <Row justify={'space-between'} style={{padding: '24px'}}>
        <Col>
          <Button
            type='text'
            className={`portfolio-tabs-btn ${tabs === 0 ? 'selected' : ''}`}
            onClick={handleTabs}
          >
            {t('CATEGORY')}
          </Button>
          <Button
          type='text'
          className={`portfolio-tabs-btn ${tabs === 1 ? 'selected' : ''}`}
          onClick={handleTabs}
          >
            {t('CLASS')}
          </Button>
        </Col>
        <Col></Col>
      </Row>
      {tabs === 0 && pieData?.category?.sort((a, b) => b.y - a.y)?.map(item => (
        <>
          <div className='client table-wrapper'>
            <div className='table-date-row'>
              <div>
                <p className='table-date-name'>{item.name?.toUpperCase()}</p>
                <p className='table-date-persent'>{(item.y * 100 / pieData.dataTotal).toFixed(2)}%</p>
              </div>
              <div className='table-date-value'>{`${oneDecimalWithSpaces(item.y)} kr`}</div>
            </div>
            <Progress 
              className='table-date-progress'
              percent={(item.y * 100 / pieData.dataTotal).toFixed(2)} 
              showInfo={false} 
              strokeColor={'#FB7B34'}
              size={{height: 2}}
            />
          </div>
        </>
      ))
      }
      {tabs === 1 && asseData?.assetClass?.sort((a, b) => b.y - a.y)?.map(item => (
        <>
          <div className='client table-wrapper'>
            <div className='table-date-row'>
              <div>
                <p className='table-date-name'>{item.name?.toUpperCase()}</p>
                <p className='table-date-persent'>{(item.y * 100 / pieData.dataTotal).toFixed(2)}%</p>
              </div>
              <div className='table-date-value'>{`${oneDecimalWithSpaces(item.y)} kr`}</div>
            </div>
            <Progress 
              className='table-date-progress'
              percent={(item.y * 100 / pieData.dataTotal).toFixed(2)} 
              showInfo={false} 
              strokeColor={'#FB7B34'}
              size={{height: 2}}
            />
          </div>
        </>
      ))
      }
    </div>
  );
};

export default ClientAssets;
ClientAssets.propTypes = {
  selectedPortfolio: PropTypes.array,
  cashes: PropTypes.array,
  currencyList: PropTypes.array,
};
