import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Col, Modal, Row } from 'antd';

import './style.scss';
import { useDispatch } from 'react-redux';
import { clearIdentification } from '../../data/store/user/userActions';

const ModalClearIdentification = ({ open, closeModal }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(clearIdentification({id: open, callback: closeModal}));
  };
  return (
    <Modal
      width={650}
      className='modal-content-paper'
      open={open}
      onCancel={closeModal}
      footer={null}
      zIndex={1620}
    >
    <Row justify='center'>
      <Col className='modal-title'>
        {t('CONFIRM_CLEAR_IN')}
      </Col>
    </Row>
    <Row justify='center' className='modal-text-wrapper'>
      <Col>
        <p className='modal-text'>{t('CLEAR_TEXT')}</p>
      </Col>
    </Row>

      <Row
        className='modal-button-wrapper'
      >
        <Button className='modal-action-btn cancel-btn' onClick={closeModal}>
          {t('CANCEL')}
        </Button>
        <Button className='modal-action-btn delete-btn' onClick={onSubmit}>
          {t('CLEAR_IN')}
        </Button>
      </Row>
    </Modal>
  );
};

export default ModalClearIdentification;

ModalClearIdentification.propTypes = {
  open: PropTypes.string,
  closeModal: PropTypes.func,
};
