/* eslint-disable multiline-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { Table, Tooltip } from 'antd';
import { numberWithSpaces, numberWithSpacesInter } from '../../helpers/formatInteger';

import { formatPercentage } from '../../helpers/formatPercentage';
import { formatDate } from '../../helpers/formatDate';
import { fractionalWithSpaces, twoDecimalWithSpaces } from '../../helpers/formatFractional';
import { decimalSeparator } from '../../helpers/decimalSeparator';

import './style.scss';
import { textSort } from '../../helpers/textSort';

export const Cash = ({
  currencyList,
  currentPortfolio,
}) => {
  const { t } = useTranslation('');

  const [tableColumns, setTableColumns] = useState();

  const getCurrencyName = (id = 1) => {
    const name = currencyList?.find(i => i.id === id);
    return name?.name;
  };

  const percentage = (value, isText) => {
    const percentage =
      Math.round(
        ((value * 100) /
          currentPortfolio
            ?.map((i) => i.value)
            .reduce((acc, el) => acc + el, 0)) *
          100
      ) / 100;
    return isText ? `${percentage} %` : percentage;
  };

  const data = currentPortfolio
  ?.map((row, index) => {
    return {
      key: row.id,
      financialInstrumentName: row?.instrumentName ?? row?.name,
      ISIN: row?.ISIN,
      unitsNumber: row?.unitsNumber,
      latest_value_sek: row?.latest_value_sek,
      latest_value_time: row.latest_value_time,
      currency_id: row?.currency_id ?? 1,
      portfolio_code: row?.portfolio_code,
      value: row.value,
      fee: row?.fee ?? 0,
      total_value: row.total_value,
      feeText: row?.fee ? row.fee + ' %' : '0 %',
      assetClass: row.financialAsset?.name,
      financialAssetCategory: row.financialAssetCategory?.name || 'TBD',
      categoryID: row.financialAssetCategory?.ID,
    };
  })
  .sort((a, b) => b.value - a.value);

  const total = data.reduce((acc, current) => current.value + acc, 0);

  const getWeight = (value) => {
    return Math.round((value / total * 100) * 100) / 100;
  };

  useEffect(() => {
    if (currentPortfolio?.length) {
      const defaultColumns = [
        {
          title: t('INSTRUMENT'),
          dataIndex: 'financialInstrumentName',
          className: 'holdings-instrument',
          width: '20%',
          sorter: (a, b) => textSort(a.financialInstrumentName, b.financialInstrumentName),
        },
        {
          title: t('PORTFOLIO'),
          dataIndex: 'portfolio_code',
          className: 'holdings-data',
          width: '8%',
          align: 'center',
          sorter: (a, b) => a.portfolio_code - b.portfolio_code,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: t('UNITS'),
          dataIndex: 'unitsNumber',
          className: 'holdings-data unitsNumber',
          align: 'right',
          width: '10%',
          sorter: (a, b) => a.unitsNumber - b.unitsNumber,
          sortDirections: ['descend', 'ascend'],
          render: (value) => {
            return value ? numberWithSpacesInter(value, 0) : '';
          },
        },
        {
          title: t('SHARE_PRICE'),
          dataIndex: 'latest_value_sek',
          className: 'holdings-data latest-value',
          align: 'right',
          width: '10%',
          sorter: (a, b) => a.latest_value_sek - b.latest_value_sek,
          sortDirections: ['descend', 'ascend'],
          render: (value, record) => {
            return (
              <Tooltip
                placement="top"
                title={
                  <span style={{ color: '#000000' }}>
                    {formatDate(record?.latest_value_time)}
                  </span>
                }
                color="white"
              >
                {numberWithSpacesInter(value) + ' ' + 'kr'}
              </Tooltip>
            );
          },
        },
        {
          title: t('CURRENCY'),
          dataIndex: 'currency_id',
          className: 'holdings-currency',
          width: '8%',
          align: 'center',
          render: (value) => getCurrencyName(value),
          sorter: (a, b) => textSort(getCurrencyName(a.currency_id), getCurrencyName(b.currency_id)),
        },
        {
          title: t('VALUE'),
          dataIndex: 'value',
          className: 'holdings-data value',
          width: '10%',
          align: 'right',
          render: (value) => {
            return `${numberWithSpacesInter(value, 2)} kr`;
          },
          sorter: (a, b) => a.value - b.value,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: t('WEIGHT'),
          dataIndex: 'weight',
          className: 'holdings-data value',
          width: '10%',
          align: 'right',
          render: (_, record) => {
            return `${formatPercentage(getWeight(record.value))}`;
          },
          sorter: (a, b) => a.value - b.value,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: t('FEE'),
          dataIndex: 'fee',
          className: 'holdings-fee',
          width: '10%',
          align: 'center',
          render: (value) => {
            return (
              <p>
                {formatPercentage(value) ||
                `${'0.00'.replace('.', decimalSeparator())} %`}
              </p>
            );
          },
          sorter: (a, b) => a.fee - b.fee,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: t('ASSET_CLASS'),
          dataIndex: 'assetClass',
          width: '14%',
          sorter: (a, b) => textSort(a.assetClass, b.assetClass),
          render: (value, record) => {
            return (
              <>
                <p className="holdings-assetclass-cell-asset">{value}</p>
                <p className="holdings-assetclass-cell-category">
                  {record.financialAssetCategory}
                </p>
              </>
            );
          },
        },
      ];
      setTableColumns(defaultColumns);
    }
  }, [currentPortfolio]);

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <div className="portfolio-table">
      <Table
        onChange={onChange}
        rowClassName={(_, index) =>
          index % 2 === 0 ? 'holdings-table-row' : 'holdings-table-row is-odd'
        }
        columns={tableColumns}
        dataSource={data}
        size="middle"
        bordered
        pagination={false}
      />
    </div>
  );
};

Cash.propTypes = {
  currencyList: PropTypes.array,
  currentPortfolio: PropTypes.array,
};
