import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Col, Row, Skeleton, Table } from 'antd';
import { twoDecimalWithSpacesEN } from '../../helpers/formatFractional';
import { numberWithSpacesInter } from '../../helpers/formatInteger';
import dayjs from 'dayjs';

const TableBuySell = ({ setId, dataBuySell, isLoading, tab, currencyName }) => {
  const { t } = useTranslation('');

  const tableData = useMemo(() => {
    if (dataBuySell) {
      const data = [...dataBuySell];
      const summ = data?.reduce((acc, item) => acc + item?.value, 0);
      const oldestDate = Math.min(...data.map((i => Math.round(i.price_time / 60))));
      let findFirst = false;
      return data?.map((i) => {
        let bold = false;
        if(Math.round(i.price_time / 60) === oldestDate && !findFirst) {
          bold = true;
          findFirst = true;
        }
        const element = { ...i, share: (i.value / summ) * 100, bold };
        return element;
      });
    }
    return [];
  }, [dataBuySell]);

  const totalData = useMemo(() => {
    if (dataBuySell) {
      const data = [...dataBuySell];
      return data?.reduce((acc, item) => acc + item?.value, 0);
    }
    return 0;
  }, [dataBuySell]);

  const columnsBuySell = useMemo(() => ([
    {
      title: t('INSTRUMENT'),
      dataIndex: 'instrument',
      align: 'left',
      width: '25%',
    },
    {
      title: t('ISIN'),
      dataIndex: 'isin',
      align: 'left',
    },
    {
      title: t('TICKER'),
      dataIndex: 'ticker',
      align: 'right',
    },
    {
      title: t('NO_SHARES'),
      dataIndex: 'unitsNumber',
      align: 'right',
      render: (value, record) => {
        return value ? `${numberWithSpacesInter(value, 4)}${record.is_sell_all ? '*' : ''}` : '';
      },
    },
    {
      title: t('SHARE_PRICE'),
      dataIndex: 'share_price',
      align: 'right',
      render: (value, record) => {
        return value ? `${numberWithSpacesInter(value, 2)} ${currencyName(record.currency_id)}` : '';
      },
    },
    {
      title: t('VALUE'),
      dataIndex: 'value',
      className: 'value',
      align: 'right',
      render: (value) => {
        return value ? `${twoDecimalWithSpacesEN(value)} kr` : '';
      },
    },
    {
      title: t('PRICE_DATE'),
      dataIndex: 'price date',
      className: 'date',
      align: 'right',
      render: (value, record) => {
        const date = !record.fx_time 
          ? record.price_time
          : Math.min(record.price_time, record.fx_time);
        return `${dayjs(date * 1000).format('YYYY-MM-DD HH:mm')}`;
      },
    },
  ]), [dataBuySell]);
  return (
    <>
    <div style={{ border: '1px solid #E9EFF6', padding: '31px 0 0' }}>
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ marginBottom: '32px', padding: '0px 40px' }}
      >
        <Col>
        {/* {isLoading
          ? <Skeleton.Input active={true} size={'small'} />
          : <h2 className="opt-paper-title">
              {t(tab === 'buy' ? 'BUY_TRANSACTIONS' : 'SELL_TRANSACTIONS')}
            </h2>
        } */}
        <Skeleton avatar={false} title={false} paragraph={{ rows: 1, width: 300 }} active loading={isLoading}>
          <h2 className="opt-paper-title">
              {`${t(tab === 'buy' ? 'BUY_TRANSACTIONS' : 'SELL_TRANSACTIONS')} `}
              {tab === 'buy' ? '' : <span className='transaction-set-text'>(transaction set id: {setId})</span>}
            </h2>
        </Skeleton>
        </Col>
        <Col>
        <Skeleton avatar={false} title={false} paragraph={{ rows: 1, width: 200 }} active loading={isLoading}>
          <>
            <span className="holdings-text">{t('TOTAL')}</span>
            <span className="holdings-value">{`${twoDecimalWithSpacesEN(totalData)} kr`} </span>
          </>
        </Skeleton>
        </Col>
      </Row>
      <Table
        className='table-buy-sell'
        rowKey={() => uuidv4()}
        rowClassName={(i, index) => {
          let name = index % 2 === 0 ? 'editable-row' : 'editable-row is-odd';
          name += i.bold ? ' bold' : ''; 
          return name;
        }
        }
        columns={columnsBuySell}
        dataSource={tableData}
        loading={isLoading}
        size="middle"
        bordered
        pagination={false}
      />
    </div>
      {tab === 'sell' &&
        <p className='note-bottom'>* {t('SELL_ALL')}</p>
      }
    </>
  );
};

export default TableBuySell;

TableBuySell.propTypes = {
  tab: PropTypes.string,
  setId: PropTypes.number,
  dataBuySell: PropTypes.array,
  currencyName: PropTypes.func,
  isLoading: PropTypes.bool,
};
