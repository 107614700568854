import { call, put } from '@redux-saga/core/effects';
import {
  SET_CLIENT_TYPE,
  SET_CLIENT_INFORMATION,
  UPDATE_CLIENT_INFO,
  SET_CLIENT_ADDRESS,
  SET_CLIENT_SCORE,
  SET_CLIENT_PHONE,
  SET_CLIENT_MARITAL,
  UPDATE_CLIENT_PHONE,
  DELETE_CLIENT_PHONE,
  SET_CLIENT_DATA,
  GET_CLIENT_DATA,
  SEND_INVITATION,
  GET_INVITATION_INFO,
  ACCEPT_INVITATION,
  SET_CLIENT_RISK_LEVEL,
  SEND_CLIENT_APPROVE,
  SET_CLIENT_FEES,
  GET_CLIENT_FEES,
  ADD_REPRESENTATIVE,
  UPDATE_REPRESENTATIVE,
  DELETE_REPRESENTATIVE,
  ADD_REPRESENTATIVE_DOC,
  CREATE_FINPLAN,
  GET_FINPLAN,
  PATCH_FINPLAN,
  DELETE_FINPLAN,
  GET_SUGGESTIONS_PORTFOLIO,
  APPROVE_SUGGESTIONS_PORTFOLIO,
  DELETE_SUGGESTIONS_PORTFOLIO,
  GET_APPROVED_PORTFOLIO,
  GET_ALL_APPROVED_PORTFOLIO,
  EXECUTE_PORTFOLIO,
  GET_CANDIDATE_TRANSACTIONS,
  GET_ALL_EXECUTED_PORTFOLIO,
  GET_ALL_IMPLEMENTED_PORTFOLIO,
  GET_EXECUTED_PORTFOLIO,
  GET_IMPLEMENTED_PORTFOLIO,
  DOWNLOAD_PDF_REPOR,
  GET_ONDOARDING_STATUS,
  POST_ONDOARDING_DATA,
  DEL_ONDOARDING,
  SET_ONDOARDING_VERIFED,
  POST_ONDOARDING_AUDIO,
  UPLOAD_ONDOARDING_DOCUMENT,
  GET_ONDOARDING_DOCUMENTS_LIST,
  DELETE_ONDOARDING_DOCUMENT,
  UPLOAD_ONDOARDING_AUDIO,
  IMPLEMENTE_PORTFOLIO,
  RESCHEDULE_ONDOARDING,
  CREATE_ACCOUNT,
  GET_ACCOUNTS_LIST,
  GET_ACCOUNT,
  GET_RELATED_ACCOUNT,
  ADD_RELATED_PERSON,
  CREATE_CLIENT_TO_ACCOUNT,
  UPDATE_RELATED_PERSON,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
  CLEAR_ACCOUNT_BY_ID,
} from './accountActionsTypes';
import { AccountService } from '../../../services/AccountService';
import { LOGIN } from '../../../constants/routes';

import _ from 'lodash';

export function * getAccountsList () {
  try {
    yield put({ type: GET_ACCOUNTS_LIST.REQUEST });
    const response = yield AccountService.getAccountsList();
    if (response.status === 200) {
      yield put({ type: GET_ACCOUNTS_LIST.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_ACCOUNTS_LIST.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({ type: GET_ACCOUNTS_LIST.ERROR, payload: e.response?.data.message });
  }
}

export function * getAccount (action) {
  try {
    yield put({ type: GET_ACCOUNT.REQUEST });
    const response = yield AccountService.getAccount(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_ACCOUNT.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_ACCOUNT.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({ type: GET_ACCOUNT.ERROR, payload: e.response.data.message });
  }
}

export function * updateAccount (action) {
  try {
    yield put({ type: UPDATE_ACCOUNT.REQUEST });
    const response = yield AccountService.updateAccount(action.payload);
    if (response.status === 200) {
      yield put({ type: UPDATE_ACCOUNT.SUCCESS, payload: response.data });
      yield put({ type: GET_ACCOUNT.ACTION, payload: {id: action.payload.accountId} });
    } else {
      yield put({
        type: UPDATE_ACCOUNT.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({ type: UPDATE_ACCOUNT.ERROR, payload: e.response.data.message });
  }
}

export function * addRelatedPerson (action) {
  try {
    yield put({ type: ADD_RELATED_PERSON.REQUEST });
    const response = yield AccountService.addRelatedPerson(action.payload);
    if (response.status === 200) {
      yield put({ type: ADD_RELATED_PERSON.SUCCESS });
      yield put({ type: GET_RELATED_ACCOUNT.ACTION, payload: action.payload.accountId});
    } else {
      yield put({
        type: ADD_RELATED_PERSON.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({ type: ADD_RELATED_PERSON.ERROR, payload: e.response.data.message });
  }
}

export function * updateRelatedPerson (action) {
  try {
    yield put({ type: UPDATE_RELATED_PERSON.REQUEST });
    const response = yield AccountService.updateRelatedPerson(action.payload);
    if (response.status === 200) {
      yield put({ type: UPDATE_RELATED_PERSON.SUCCESS });
      yield put({ type: GET_RELATED_ACCOUNT.ACTION, payload: action.payload.accountId});
    } else {
      yield put({
        type: UPDATE_RELATED_PERSON.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({ type: UPDATE_RELATED_PERSON.ERROR, payload: e.response.data.message });
  }
}

export function * deleteRelatedPerson (action) {
  const {callback, accountId} = action.payload;
  try {
    yield put({ type: ADD_RELATED_PERSON.REQUEST });
    const response = yield AccountService.deleteRelatedPerson(action.payload);
    if (response.status === 200) {
      yield put({ type: ADD_RELATED_PERSON.SUCCESS });
      yield put({ type: GET_RELATED_ACCOUNT.ACTION, payload: accountId});
      callback();
    } else {
      yield put({
        type: ADD_RELATED_PERSON.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({ type: ADD_RELATED_PERSON.ERROR, payload: e.response.data.message });
  }
}

export function * getRelatedAccount (action) {
  try {
    yield put({ type: GET_RELATED_ACCOUNT.REQUEST });
    const response = yield AccountService.getRelatedAccount(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_RELATED_ACCOUNT.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_RELATED_ACCOUNT.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({ type: GET_RELATED_ACCOUNT.ERROR, payload: e.response.data.message });
  }
}

export function * createClientToAccount (action) {
  try {
    const { data: {accountId, client}, closeModal } = action.payload;
    yield put({ type: CREATE_CLIENT_TO_ACCOUNT.REQUEST });
    const response = yield AccountService.createClientToAccount(accountId, client);
    if (response.status === 200) {
      yield put({
        type: CREATE_CLIENT_TO_ACCOUNT.SUCCESS,
        payload: response.data.client,
      });
      closeModal();
    } else {
      yield put({
        type: CREATE_CLIENT_TO_ACCOUNT.ERROR,
        payload: response?.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: CREATE_CLIENT_TO_ACCOUNT.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}


export function * createAccount (action) {
  try {
    const { data, history } = action.payload;
    yield put({ type: CREATE_ACCOUNT.REQUEST });
    const response = yield AccountService.createAccount(data);
    if (response.status === 200) {
      yield put({
        type: CREATE_ACCOUNT.SUCCESS,
        payload: response.data,
      });
      history.replace(`/accounts/${response.data.id}/inform/details`);
    } else {
      //TODO del this
      yield put({
        type: CREATE_ACCOUNT.ERROR,
        payload: response?.response?.data?.message,
      });

    }
  } catch (e) {
    yield put({
      type: CREATE_ACCOUNT.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * sendInvitation (action) {
  try {
    const { id, email } = action.payload;
    yield put({ type: SEND_INVITATION.REQUEST });
    const response = yield AccountService.sendInvitation({ id, email });
    if (response.status === 200) {
      yield AccountService.sendClientApprove({
        id,
        status: 'CLIENT_STATUS_INVITED',
      });
      yield put({ type: SEND_INVITATION.SUCCESS });
      yield put({ type: GET_ACCOUNTS_LIST.REQUEST });
      const resp = yield AccountService.getAccount(id);
      if (resp.status === 200) {
        yield put({ type: GET_ACCOUNT.SUCCESS, payload: resp.data });
      } else {
        yield put({
          type: GET_ACCOUNT.ERROR,
          payload: resp.response.data.message,
        });
      }
    } else {
      yield put({
        type: SEND_INVITATION.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: CREATE_ACCOUNT.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * getInvitationInfo (action) {
  try {
    const { inviteHash } = action.payload;
    yield put({ type: GET_INVITATION_INFO.REQUEST });
    const response = yield AccountService.getInvitationInfo({ inviteHash });
    if (response.status === 200) {
      yield put({ type: GET_INVITATION_INFO.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_INVITATION_INFO.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_INVITATION_INFO.ERROR,
      payload: e.response.data.message,
    });
  }
}
export function * acceptInvitation (action) {
  try {
    const { history } = action.payload;
    yield put({ type: ACCEPT_INVITATION.REQUEST });
    const response = yield AccountService.acceptInvitation(action.payload);
    if (response.status === 200) {
      yield put({ type: ACCEPT_INVITATION.SUCCESS });
      history.push(LOGIN);
    } else {
      yield put({
        type: ACCEPT_INVITATION.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: ACCEPT_INVITATION.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * setClientType (action) {
  try {
    yield put({ type: SET_CLIENT_TYPE.REQUEST });
    const response = yield AccountService.setClientType(action.payload);
    if (response.status === 200) {
      yield put({ type: SET_CLIENT_TYPE.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: SET_CLIENT_TYPE.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: SET_CLIENT_TYPE.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * addClientScore (action) {
  try {
    yield put({ type: SET_CLIENT_SCORE.REQUEST });
    const response = yield AccountService.addClientScore(action.payload);
    if (response.status === 200) {
      yield put({ type: SET_CLIENT_SCORE.SUCCESS });
    } else {
      yield put({
        type: SET_CLIENT_SCORE.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: SET_CLIENT_SCORE.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * addClientPhone (action) {
  try {
    yield put({ type: SET_CLIENT_PHONE.REQUEST });
    const response = yield AccountService.addClientPhone(action.payload);
    if (response.status === 200) {
      yield put({ type: SET_CLIENT_PHONE.SUCCESS });
    } else {
      yield put({
        type: SET_CLIENT_PHONE.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: SET_CLIENT_PHONE.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * updateClientPhone (action) {
  console.log('🚀 ~ *updateClientPhone ~ action.payload:', action.payload);
  try {
    yield put({ type: UPDATE_CLIENT_PHONE.REQUEST });
    const response = yield AccountService.updateClientPhone(action.payload);
    if (response.status === 200) {
      yield put({ type: UPDATE_CLIENT_PHONE.SUCCESS });
    } else {
      yield put({
        type: UPDATE_CLIENT_PHONE.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: SET_CLIENT_TYPE.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * deleteClientPhone (action) {
  try {
    const response = yield AccountService.deleteClientPhone(action.payload);
    if (response.status === 200) {
      const response = yield AccountService.getAccount(action.payload.id);
      if (response.status === 200) {
        yield put({ type: GET_ACCOUNT.SUCCESS, payload: response.data });
      } else {
        yield put({
          type: GET_ACCOUNT.ERROR,
          payload: response.response.data.message,
        });
      }
    } else {
      yield put({
        type: DELETE_CLIENT_PHONE.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: DELETE_CLIENT_PHONE.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * updateClientInfo (action) {
  const { history, path, id } = action.payload;
  try {
    yield put({ type: UPDATE_CLIENT_INFO.REQUEST });
    const response = yield AccountService.updateClientInfo(action.payload);
    if (response.status === 200) {
      yield put({ type: UPDATE_CLIENT_INFO.SUCCESS });
      yield put({ type: GET_ACCOUNT.ACTION, payload: { id } });
      yield put({ type: GET_ACCOUNTS_LIST.ACTION });
      if (path) {
        history.push(path);
      }
    } else {
      yield put({
        type: UPDATE_CLIENT_INFO.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: UPDATE_CLIENT_INFO.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * updateClientAddress (action) {
  try {
    yield put({ type: SET_CLIENT_ADDRESS.REQUEST });
    const response = yield AccountService.setClientAddress(action.payload);
    if (response.status === 200) {
      yield put({ type: SET_CLIENT_ADDRESS.SUCCESS });
      const clientResponse = yield AccountService.getAccount(action.payload.id);
      if (clientResponse.status === 200) {
        yield put({ type: GET_ACCOUNT.SUCCESS, payload: clientResponse.data });
      } else {
        yield put({
          type: GET_ACCOUNT.ERROR,
          payload: clientResponse.response.data.message,
        });
      }
    } else {
      yield put({
        type: UPDATE_CLIENT_INFO.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: UPDATE_CLIENT_INFO.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * setClientInformation (action) {
  try {
    const { id, client, history } = action.payload;
    const clienInfo = {
      id,
      client: {
        email: client?.email,
        firstName: client?.firstName,
        lastName: client?.lastName,
      },
    };

    const clientAddress = {
      id,
      address: {
        client_id: +id,
        postal: client?.postal,
        zip_code: client?.zip_code,
        city: client?.city,
        careOf: client?.careOf ?? '',
      },
    };

    const clientMarital = {
      id,
      marital: {
        client_id: +id,
        marital_status: client?.marital_status,
        main_occupation: client?.main_occupation,
        children_on_support: client?.more_info,
      },
    };

    const clientScore = {
      id,
      score: client?.score,
    };

    yield put({ type: SET_CLIENT_INFORMATION.REQUEST });
    yield put({ type: UPDATE_CLIENT_INFO.REQUEST });
    const responseInfo = yield AccountService.updateClientInfo(clienInfo);
    yield put({ type: SET_CLIENT_ADDRESS.REQUEST });
    const responseAddress = yield AccountService.setClientAddress(clientAddress);
    yield put({ type: SET_CLIENT_MARITAL.REQUEST });
    const responseMarital = yield AccountService.setClientMaritalStatus(
      clientMarital
    );
    yield put({ type: SET_CLIENT_SCORE.REQUEST });
    const responseScore = yield AccountService.addClientScore(clientScore);
    if (responseInfo.status === 200) {
      // yield put({ type: UPDATE_CLIENT_INFO.SUCCESS });
    } else {
      yield put({
        type: UPDATE_CLIENT_INFO.ERROR,
        payload: responseInfo.response.data.message,
      });
    }
    if (responseAddress.status === 200) {
      // yield put({ type: SET_CLIENT_ADDRESS.SUCCESS });
    } else {
      yield put({
        type: SET_CLIENT_ADDRESS.ERROR,
        payload: responseAddress.response.data.message,
      });
    }
    if (responseMarital.status === 200) {
      // yield put({ type: SET_CLIENT_MARITAL.SUCCESS });
    } else {
      yield put({
        type: SET_CLIENT_MARITAL.ERROR,
        payload: responseAddress.response.data.message,
      });
    }
    if (responseScore.status === 200) {
      // yield put({ type: SET_CLIENT_SCORE.SUCCESS });
    } else {
      yield put({
        type: SET_CLIENT_SCORE.ERROR,
        payload: responseScore.response.data.message,
      });
    }
    yield put({ type: SET_CLIENT_INFORMATION.SUCCESS });
    yield history.push(`/dashboard/${id}`);
  } catch (e) {
    yield put({
      type: SET_CLIENT_INFORMATION.ERROR,
      payload: 'SET_CLIENT_INFORMATION Error ',
    });
  }
}

export function * setClientData (action) {
  try {
    yield put({ type: SET_CLIENT_DATA.REQUEST });
    const response = yield AccountService.setClientData(action.payload);
    if (response.status === 200) {
      response.data?.success === true
        ? yield put({
          type: SET_CLIENT_DATA.SUCCESS,
          payload: {
            ...action.payload.fields,
            risk_level: response.data?.risk_level ?? 0,
          },
        })
        : yield put({ type: SET_CLIENT_DATA.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: SET_CLIENT_DATA.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: SET_CLIENT_DATA.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * getClientData (action) {
  try {
    yield put({ type: GET_CLIENT_DATA.REQUEST });
    const response = yield AccountService.getClientData(action.payload);
    if (response.status === 200) {
      const { data } = response;
      if (!data.type) {
        data.type = action.payload.name;
      }
      yield put({ type: GET_CLIENT_DATA.SUCCESS, payload: data });
    } else {
      yield put({
        type: GET_CLIENT_DATA.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_CLIENT_DATA.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * setRiskLevel (action) {
  try {
    // const { id, history } = action.payload;
    yield put({ type: SET_CLIENT_RISK_LEVEL.REQUEST });
    const response = yield AccountService.setRiskLevel(action.payload);
    if (response.status === 200) {
      yield put({ type: SET_CLIENT_RISK_LEVEL.SUCCESS });
      // yield history.push(`/dashboard/${id}`);
    } else {
      yield put({
        type: SET_CLIENT_RISK_LEVEL.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: SET_CLIENT_RISK_LEVEL.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * sendClientApprove (action) {
  try {
    yield put({ type: SEND_CLIENT_APPROVE.REQUEST });
    const response = yield AccountService.sendClientApprove(action.payload);
    if (response.status === 200) {
      yield put({ type: SEND_CLIENT_APPROVE.SUCCESS });
    } else {
      yield put({
        type: SEND_CLIENT_APPROVE.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: SEND_CLIENT_APPROVE.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * setClientFees (action) {
  try {
    const { id, history } = action.payload;
    yield put({ type: SET_CLIENT_FEES.REQUEST });
    const response = yield AccountService.setClientFees(action.payload);
    if (response.status === 200) {
      yield put({ type: SET_CLIENT_FEES.SUCCESS });
      yield history.push(`/dashboard/${id}`);
    } else {
      yield put({
        type: SET_CLIENT_FEES.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: SET_CLIENT_FEES.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * getClientFees (action) {
  try {
    yield put({ type: GET_CLIENT_FEES.REQUEST });
    const response = yield AccountService.getClientFees(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_CLIENT_FEES.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_CLIENT_FEES.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_CLIENT_FEES.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * addRepresentative (action) {
  try {
    yield put({ type: ADD_REPRESENTATIVE.REQUEST });
    const response = yield AccountService.addRepresentative(action.payload);
    if (response.status === 200) {
      yield put({ type: ADD_REPRESENTATIVE.SUCCESS });
      if (response?.data && action.payload.docArray.length > 0) {
        const { id, representativeId, docArray } = action.payload;
        docArray.ForEach((item) => {
          addRepresentativeDoc(id, representativeId, item);
        });
      }
    } else {
      yield put({
        type: ADD_REPRESENTATIVE.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: ADD_REPRESENTATIVE.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * updateRepresentative (action) {
  try {
    yield put({ type: UPDATE_REPRESENTATIVE.REQUEST });
    const response = yield AccountService.updateRepresentative(action.payload);
    if (response.status === 200) {
      yield put({ type: UPDATE_REPRESENTATIVE.SUCCESS });
    } else {
      yield put({
        type: UPDATE_REPRESENTATIVE.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: UPDATE_REPRESENTATIVE.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * deleteRepresentative (action) {
  try {
    yield put({ type: DELETE_REPRESENTATIVE.REQUEST });
    const response = yield AccountService.deleteRepresentative(action.payload);
    if (response.status === 200) {
      yield put({ type: DELETE_REPRESENTATIVE.SUCCESS });
      const clientResponse = yield AccountService.getAccount(action.payload.id);
      if (clientResponse.status === 200) {
        yield put({ type: GET_ACCOUNT.SUCCESS, payload: clientResponse.data });
      } else {
        yield put({
          type: GET_ACCOUNT.ERROR,
          payload: clientResponse.response.data.message,
        });
      }
    } else {
      yield put({
        type: DELETE_REPRESENTATIVE.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: DELETE_REPRESENTATIVE.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * addRepresentativeDoc (id, representativeId, item) {
  try {
    yield put({ type: ADD_REPRESENTATIVE_DOC.REQUEST });
    yield AccountService.addRepresentativeDoc({
      id,
      representativeId,
      file: item.file,
      type: item.type,
    });
  } catch (e) {
    yield put({
      type: ADD_REPRESENTATIVE_DOC.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * deleteAccount (action) {
  const { id, afterDel } = action.payload;
  try {
    yield put({ type: DELETE_ACCOUNT.REQUEST });
    const response = yield AccountService.deleteAccount(id);
    if (response.status === 200) {
      yield put({ type: DELETE_ACCOUNT.SUCCESS, payload: +id });
      yield put({ type: CLEAR_ACCOUNT_BY_ID.ACTION });
      afterDel();
    } else {
      yield put({
        type: DELETE_ACCOUNT.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({ type: DELETE_ACCOUNT.ERROR, payload: e.response.data.message });
  }
}

export function * getFinPlan (action) {
  const id = action.payload;
  try {
    yield put({ type: GET_FINPLAN.REQUEST });
    const response = yield AccountService.getFinPlan(id);
    if (response.status === 200) {
      yield put({ type: GET_FINPLAN.SUCCESS, payload: response?.data });
    } else {
      yield put({
        type: GET_FINPLAN.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({ type: GET_FINPLAN.ERROR, payload: e?.response?.data.message });
  }
}

export function * createFinPlan (action) {
  const { history, ...rest } = action.payload;
  try {
    yield put({ type: CREATE_FINPLAN.REQUEST });
    const response = yield AccountService.createFinPlan(rest);
    if (response.status === 200) {
      yield put({ type: CREATE_FINPLAN.SUCCESS, payload: response?.data });
      history.push(`clients/${rest.clientId}/lifetiming/${response?.data?.id}`);
    } else {
      yield put({
        type: CREATE_FINPLAN.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: CREATE_FINPLAN.ERROR,
      payload: e?.response?.data.message,
    });
  }
}

export function * patchFinPlan (action) {
  const { imageUploaded, oldIds, key, ...rest } = action.payload;
  try {
    yield put({ type: PATCH_FINPLAN.REQUEST });
    const response = yield AccountService.patchFinPlan(rest);
    if (response.status === 200) {
      if (imageUploaded) {
        const newIds = response?.data.expenses?.map((i) => i.id);
        const id = key || _.difference(newIds, oldIds)[0];
        const responseUploaded = yield AccountService.uploadImage(
          imageUploaded,
          rest.finplanId,
          id
        );
        if (responseUploaded.status === 200) {
          console.log('Upload SUCCESS');
          yield put({ type: GET_FINPLAN.ACTION, payload: rest.clientId });
        } else {
          yield put({ type: PATCH_FINPLAN.ERROR, payload: 'Upload failed' });
        }
      }
      yield put({ type: PATCH_FINPLAN.SUCCESS, payload: response?.data });
    } else {
      yield put({
        type: PATCH_FINPLAN.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: PATCH_FINPLAN.ERROR,
      payload: e?.response?.data.message,
    });
  }
}

export function * deleteFinPlan (action) {
  try {
    yield put({ type: DELETE_FINPLAN.REQUEST });
    const response = yield AccountService.deleteFinPlan(action.payload);
    if (response.status === 200) {
      yield put({ type: DELETE_FINPLAN.SUCCESS, payload: response?.data });
      yield put({ type: GET_FINPLAN.ACTION, payload: action.payload.clientId });
    } else {
      yield put({
        type: DELETE_FINPLAN.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: DELETE_FINPLAN.ERROR,
      payload: e?.response?.data.message,
    });
  }
}

export function * getSuggestionsPortfolio (action) {
  try {
    yield put({ type: GET_SUGGESTIONS_PORTFOLIO.REQUEST });
    const response = yield AccountService.getSuggestionsPortfolio(
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: GET_SUGGESTIONS_PORTFOLIO.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_SUGGESTIONS_PORTFOLIO.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_SUGGESTIONS_PORTFOLIO.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * getApprovedPortfolio (action) {
  try {
    yield put({ type: GET_APPROVED_PORTFOLIO.REQUEST });
    const response = yield AccountService.getApprovedPortfolio(
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: GET_APPROVED_PORTFOLIO.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_APPROVED_PORTFOLIO.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_APPROVED_PORTFOLIO.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * getExecutedPortfolio (action) {
  try {
    yield put({ type: GET_EXECUTED_PORTFOLIO.REQUEST });
    const response = yield AccountService.getExecutedPortfolio(
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: GET_EXECUTED_PORTFOLIO.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_EXECUTED_PORTFOLIO.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_EXECUTED_PORTFOLIO.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * getImplementedPortfolio (action) {
  try {
    yield put({ type: GET_IMPLEMENTED_PORTFOLIO.REQUEST });
    const response = yield AccountService.getImplementedPortfolio(
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: GET_IMPLEMENTED_PORTFOLIO.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_IMPLEMENTED_PORTFOLIO.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_IMPLEMENTED_PORTFOLIO.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * getAllApprovedPortfolio () {
  try {
    yield put({ type: GET_ALL_APPROVED_PORTFOLIO.REQUEST });
    const response = yield AccountService.getAllApprovedPortfolio();
    if (response.status === 200) {
      yield put({
        type: GET_ALL_APPROVED_PORTFOLIO.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_ALL_APPROVED_PORTFOLIO.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_ALL_APPROVED_PORTFOLIO.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * getAllExecutedPortfolio () {
  try {
    yield put({ type: GET_ALL_EXECUTED_PORTFOLIO.REQUEST });
    const response = yield AccountService.getAllExecutedPortfolio();
    if (response.status === 200) {
      yield put({
        type: GET_ALL_EXECUTED_PORTFOLIO.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_ALL_EXECUTED_PORTFOLIO.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_ALL_EXECUTED_PORTFOLIO.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * getAllImplementedPortfolio () {
  try {
    yield put({ type: GET_ALL_IMPLEMENTED_PORTFOLIO.REQUEST });
    const response = yield AccountService.getAllImplementedPortfolio();
    if (response.status === 200) {
      yield put({
        type: GET_ALL_IMPLEMENTED_PORTFOLIO.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_ALL_IMPLEMENTED_PORTFOLIO.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_ALL_IMPLEMENTED_PORTFOLIO.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * approveSuggestions (action) {
  const { userId } = action.payload;
  try {
    yield put({ type: APPROVE_SUGGESTIONS_PORTFOLIO.REQUEST });
    const response = yield AccountService.approveSuggestions(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_SUGGESTIONS_PORTFOLIO.ACTION, payload: userId });
      yield put({
        type: APPROVE_SUGGESTIONS_PORTFOLIO.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: APPROVE_SUGGESTIONS_PORTFOLIO.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: APPROVE_SUGGESTIONS_PORTFOLIO.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * deleteSuggestions (action) {
  const { userId, candidatType } = action.payload;
  try {
    yield put({ type: DELETE_SUGGESTIONS_PORTFOLIO.REQUEST });
    const response = yield AccountService.deleteSuggestions(action.payload);
    if (response.status === 200) {
      if (candidatType === 'suggestions') {
        yield put({ type: GET_SUGGESTIONS_PORTFOLIO.ACTION, payload: userId });
      } else if (candidatType === 'approved') {
        yield put({ type: GET_APPROVED_PORTFOLIO.ACTION, payload: userId });
      } else if (candidatType === 'admin-approved') {
        yield put({ type: GET_ALL_APPROVED_PORTFOLIO.ACTION });
      }
      yield put({
        type: DELETE_SUGGESTIONS_PORTFOLIO.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: DELETE_SUGGESTIONS_PORTFOLIO.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: DELETE_SUGGESTIONS_PORTFOLIO.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * getCandidatTransactions (action) {
  try {
    yield put({ type: GET_CANDIDATE_TRANSACTIONS.REQUEST });
    const response = yield AccountService.getCandidatTransactions(action.payload);
    if (response.status === 200) {
      yield put({
        type: GET_CANDIDATE_TRANSACTIONS.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_CANDIDATE_TRANSACTIONS.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_CANDIDATE_TRANSACTIONS.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * executeApprovedPortfolio (action) {
  const { callBack, ...resp } = action.payload;
  try {
    yield put({ type: EXECUTE_PORTFOLIO.REQUEST });
    const response = yield AccountService.executeApprovedPortfolio(resp);
    if (response.status === 200) {
      yield put({ type: GET_ALL_APPROVED_PORTFOLIO.ACTION });
      yield put({
        type: EXECUTE_PORTFOLIO.SUCCESS,
        payload: response?.data,
      });
      callBack();
    } else {
      yield put({
        type: EXECUTE_PORTFOLIO.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: EXECUTE_PORTFOLIO.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * implementePortfolio (action) {
  const { callBack, ...resp } = action.payload;
  try {
    yield put({ type: IMPLEMENTE_PORTFOLIO.REQUEST });
    const response = yield AccountService.implementePortfolio(resp);
    if (response.status === 200) {
      yield put({type: GET_ALL_EXECUTED_PORTFOLIO.ACTION});
      yield put({ type: IMPLEMENTE_PORTFOLIO.SUCCESS});
      callBack();
    } else {
      yield put({
        type: IMPLEMENTE_PORTFOLIO.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: IMPLEMENTE_PORTFOLIO.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * downloadPdfReport (action) {
  const {callBack, ...rest} = action.payload;
  try {
    yield put({ type: DOWNLOAD_PDF_REPOR.REQUEST });
    const response = yield AccountService.downloadPdfReport(rest);
    if (response.status === 200) {
      const options = response.headers['content-disposition'].split('=');
      const data = response.data;
      const blob = new Blob([data], { type: rest.type === 'pdf' 
        ? 'application/pdf' 
        : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml;charset=UTF-8' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = options[options.length - 1];
      link.click();
      yield put({
        type: DOWNLOAD_PDF_REPOR.SUCCESS,
        payload: response?.data,
      });
      callBack();
    } else {
      yield put({
        type: DOWNLOAD_PDF_REPOR.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: DOWNLOAD_PDF_REPOR.ERROR,
      payload: e?.response?.data?.message,
    });
  }
}

export function * getOnboardingStatus (action) {
  try {
    if (!action?.payload) throw new Error('Need argument clientID');
    yield put({ type: GET_ONDOARDING_STATUS.REQUEST });
    const response = yield call(AccountService.getOnboardingStatus, action.payload);
    if (response.status === 200) {
      yield put({ type: GET_ONDOARDING_STATUS.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_ONDOARDING_STATUS.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_ONDOARDING_STATUS.ERROR,
      payload: e?.response?.data.message,
    });
  }
}

export function * postOnboardingData (action) {
  try {
    if (!action?.payload) throw new Error('Need arguments');
    yield put({ type: POST_ONDOARDING_DATA.REQUEST });
    const response = yield call(AccountService.postOnboardingData, action.payload);
    if (response.status === 200) {
      yield put({ type: POST_ONDOARDING_DATA.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: POST_ONDOARDING_DATA.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_ONDOARDING_STATUS.ERROR,
      payload: e?.response?.data.message,
    });
  }
}

export function * delOnboarding (action) {
  try {
    if (!action?.payload) throw new Error('Need argument clientID');
    yield put({ type: DEL_ONDOARDING.REQUEST });
    const response = yield call(AccountService.delOnboarding, action.payload);
    if (response.status === 200) {
      yield put({ type: DEL_ONDOARDING.SUCCESS, payload: response.data });
      yield put(({ type: GET_ONDOARDING_STATUS.ACTION, payload: { clientId: action.payload } }));
    } else {
      yield put({
        type: DEL_ONDOARDING.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: DEL_ONDOARDING.ERROR,
      payload: e?.response?.data.message,
    });
  }
}

export function * setOnboardingVerifed (action) {
  try {
    if (!action?.payload) throw new Error('Need argument: verifed data');
    yield put({ type: SET_ONDOARDING_VERIFED.REQUEST });
    const response = yield call(AccountService.setOnboardingVerifed, action.payload);
    if (response.status === 200) {
      yield put({ type: SET_ONDOARDING_VERIFED.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: SET_ONDOARDING_VERIFED.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: SET_ONDOARDING_VERIFED.ERROR,
      payload: e?.response?.data.message,
    });
  }
}

export function * rescheduleOnboarding (action) {
  console.log('🚀 ~ *rescheduleOnboarding ~ action:', action);
  try {
    if (!action?.payload) throw new Error('Need argument: verifed data');
    yield put({ type: RESCHEDULE_ONDOARDING.REQUEST });
    const response = yield call(AccountService.rescheduleOnboarding, action.payload);
    console.log('🚀 ~ *rescheduleOnboarding ~ response:', response);
    if (response.status === 200) {
      yield put({ type: RESCHEDULE_ONDOARDING.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: RESCHEDULE_ONDOARDING.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: RESCHEDULE_ONDOARDING.ERROR,
      payload: e?.response?.data.message,
    });
  }
}

export function * postOnboardingAudio (action) {
  try {
    if (!action?.payload) throw new Error('Need argument clientID, file');
    yield put({ type: POST_ONDOARDING_AUDIO.REQUEST });
    const response = yield call(AccountService.postOnboardingAudio, action.payload);
    if (response.status === 200) {
      yield put({ type: POST_ONDOARDING_AUDIO.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: POST_ONDOARDING_AUDIO.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: POST_ONDOARDING_AUDIO.ERROR,
      payload: e?.response?.data.message,
    });
  }
}

export function * uploadOnboardingDocument (action) {
  try {
    yield put({ type: UPLOAD_ONDOARDING_DOCUMENT.REQUEST });
    const response = yield AccountService.uploadOnboardingDocument(action.payload);
    if (response.status === 200) {
      yield put({ type: UPLOAD_ONDOARDING_DOCUMENT.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: UPLOAD_ONDOARDING_DOCUMENT.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: UPLOAD_ONDOARDING_DOCUMENT.ERROR,
      payload: e?.response?.data.message,
    });
  }
}

export function * getOnboardingDocumentsList (action) {
  try {
    if (!action?.payload) throw new Error('Need argument clientID, id, type. files');
    yield put({ type: GET_ONDOARDING_DOCUMENTS_LIST.REQUEST });
    const response = yield call(AccountService.getOnboardingDocumentsList, action.payload);
    if (response.status === 200) {
      yield put({ type: GET_ONDOARDING_DOCUMENTS_LIST.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_ONDOARDING_DOCUMENTS_LIST.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_ONDOARDING_DOCUMENTS_LIST.ERROR,
      payload: e?.response?.data.message,
    });
  }
}

export function * deleteOnboardingDocument (action) {
  try {
    if (!action?.payload) throw new Error('Need argument clientID, id, type. files');
    yield put({ type: DELETE_ONDOARDING_DOCUMENT.REQUEST });
    const response = yield call(AccountService.deleteOnboardingDocument, action.payload);
    if (response.status === 200) {
      yield put({ type: DELETE_ONDOARDING_DOCUMENT.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: DELETE_ONDOARDING_DOCUMENT.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: DELETE_ONDOARDING_DOCUMENT.ERROR,
      payload: e?.response?.data.message,
    });
  }
}

export function * uploadOnboardingAudio (action) {
  const {clientId} = action.payload;
  try {
    yield put({ type: UPLOAD_ONDOARDING_AUDIO.REQUEST });
    const response = yield AccountService.uploadOnboardingAudio(action.payload);
    if (response.status === 200) {
      yield put({ type: UPLOAD_ONDOARDING_AUDIO.SUCCESS });
      yield put({ type: GET_ONDOARDING_STATUS.ACTION, payload: {clientId} });
    } else {
      yield put({
        type: UPLOAD_ONDOARDING_AUDIO.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: UPLOAD_ONDOARDING_AUDIO.ERROR,
      payload: e?.response?.data.message,
    });
  }
}