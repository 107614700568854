import { decimalSeparator } from './decimalSeparator';
import { numberWithSpaces, numberWithSpacesInter } from './formatInteger';

export const fractionalWithSpaces = (x) => {
  const separator = decimalSeparator();
  const rest = x % 1 === 0 ? `${separator}00` : '';
  if (!x || Number(x) === 0 || isNaN(+x)) return '0';
  if (Math.trunc(+x) === +x) return `${numberWithSpaces(x)}${rest}`;
  const int = numberWithSpaces(Math.trunc(+x));
  const round = Math.round(Number(x) * 10000) / 10000;
  const fract = round.toString().split('.')[1];
  return `${int}${separator}${fract}`;
};

export const twoDecimalWithSpaces = (x) => {
  const separator = decimalSeparator();
  if (!x) return `0${separator}00`;

  const formattedNumber = x.toFixed(2);
  let [integerPart, decimalPart] = formattedNumber.split('.');
  integerPart = numberWithSpaces(integerPart);

  return `${integerPart}${separator}${decimalPart}`;
};

export const twoDecimalWithSpacesEN = (x) => {
  const separator = decimalSeparator();
  if (!x) return `0${separator}00`;

  const formattedNumber = x.toFixed(2);
  let [integerPart, decimalPart] = formattedNumber.split('.');
  integerPart = numberWithSpacesInter(integerPart);

  return `${integerPart}${separator}${decimalPart}`;
};

export const oneDecimalWithSpaces = (x) => {
  const separator = decimalSeparator();
  if (!x) return `0${separator}0`;

  const formattedNumber = x.toFixed(1);
  let [integerPart, decimalPart] = formattedNumber.split('.');
  integerPart = numberWithSpaces(integerPart);

  return `${integerPart}${separator}${decimalPart}`;
};
