import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPortfolio } from '../../data/store/portfolio/portfolioActions';

import ClientTopSwitcher from '../../components/ClientTopSwitcher/ClientTopSwitcher';
import ClientPortfolioList from './ClientPortfolioList/ClientPortfolioList';
import PortfolioTab from './PortfolioTab/PortfolioTab';
import { getCurrency, getInstrumentsAll } from '../../data/store/instruments/instrumentsActions';
import ClientAssets from './PortfolioTab/ClientAssets';
import FooterHoldings from './FooterHoldings/FooterHoldings';

import './style.scss';

const ClientOverview = ({accountId}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const [switcher, setSwitcher] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [tabs , setTabs ] = useState(1);

  const { currencyList } = useSelector((state) => state.instrumentsReducer);
  const cashes = useSelector((state) => state.instrumentsReducer?.cashes?.list);
  const portfolios = useSelector(state => state.portfolioReducer.portfolioList?.list);
  const {isLoading} = useSelector(state => state.portfolioReducer);

  useEffect(() => {
    dispatch(getCurrency());
    dispatch(getInstrumentsAll({ limit: -1, offset: 0, type: 'cashes-only' }));
  }, []);

  useEffect(() => {
    if(accountId) {
      dispatch(getAllPortfolio(accountId));
    }
  }, [accountId]);

  const footerData = useMemo(() => {
    const getCashInstrumentRate = (id) => {
      const cash = cashes?.find((i) => i.ID === id);
      return cash?.latest_value_sek;
    };
    let total = 0;
    if(tabs === 1) {
      selectedPortfolio?.forEach( item => {
        total += item.portfolio_content.reduce((acc, current) =>  acc + current.value, 0);
        total += item.unlisted_content.reduce((acc, current) =>  acc + current.value, 0);
      });
    } else if(tabs === 2) {
      selectedPortfolio?.forEach( item => {
        total += item.portfolio_cashes?.reduce((acc, current) =>  acc + current.amount * getCashInstrumentRate(current.instrument_id), 0) ?? 0;
      });
    }
    const footerData = {total};
    if(selectedRowKeys?.length === 1) {
      footerData.return = selectedPortfolio[0]?.return;
      footerData.cvar = selectedPortfolio[0]?.cvar;
      footerData.vol = selectedPortfolio[0]?.vol;
    }
    return footerData;
  }, [selectedPortfolio, tabs]);
  
  const switchertext = ['TOTAL', 'SPLIT'];

  const handleClick = () => {
    const seter = switcher == 0 ? 1 : 0;
    setSwitcher(seter);
  };


  return (
    <div className="client profile-wrap" style={{ position: 'relative' }}>
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ marginBottom: '', padding: '24px' }}
      >
        <Col className='client portfolio-counter-wrap'>
          <h2 className="client settings-title">{t('PORTFOLIOS')}</h2>
          <span className='portfolio-counter'>{portfolios?.length ?? 0}</span>
        </Col>
        <Col>
          <ClientTopSwitcher
            text={switchertext}
            switcher={switcher}
            handleClick={handleClick}
          />
        </Col>
      </Row>
      <ClientPortfolioList
        list={portfolios}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        isLoading={isLoading}
      />
        <div className='summary-data-wrapper'>
          <div className='portfolio-side-wrap'>
            <PortfolioTab
              selectedRowKeys={selectedRowKeys}
              setSelectedPortfolio={setSelectedPortfolio}
              portfolios={portfolios}
              tabs={tabs}
              setTabs={setTabs}
            />
            <FooterHoldings
              width={'100%'} 
              totalOnly={selectedRowKeys?.length !== 1}
              data={footerData}
            />
          </div>
          <ClientAssets 
            list={portfolios}
            cashes={cashes}
            currencyList={currencyList}
            selectedRowKeys={selectedRowKeys}
            selectedPortfolio={selectedPortfolio}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        </div>

    </div>
  );
};

export default ClientOverview;

ClientOverview.propTypes = {
  accountId: PropTypes.string,
};