/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  // useDispatch,
  useSelector,
} from 'react-redux';

import { Button, Divider, Layout, Typography } from 'antd';

import LogoType from '../../assets/lt_full_logo.png';

import { ReactComponent as LogoDark } from '../../assets/life-logo-dark.svg';
import { ReactComponent as Checked } from '../../assets/dot-menu-checked.svg';

import { ReactComponent as TogglerLeft } from '../../assets/double-arrows-left.svg';
import { ReactComponent as TogglerRight } from '../../assets/double-arrows-right.svg';
import { ReactComponent as Owerview } from '../../assets/overview-icon.svg';
import { ReactComponent as Document } from '../../assets/document-icon.svg';
import { ReactComponent as AccountDetail } from '../../assets/account-detail-icon.svg';
import { ReactComponent as SupportIcon } from '../../assets/support-icon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/settings-icon.svg';
import { ReactComponent as Logout } from '../../assets/logout-icon.svg';
import { ReactComponent as ListCollapse } from '../../assets/list-collapse-icon.svg';


import { logOut } from '../../data/store/user/userActions';

import './style.scss';

const { Sider } = Layout;
const { Text } = Typography;

const SideBarClient = ({ theme, selectedAccount, setSelectedAccount }) => {
  const [collapsed, setCollapsed] = useState(false);

  const { t } = useTranslation('');
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('collapsed', collapsed);
    
  }, [collapsed]);
  

  const { isLoading, role, id, first_name, last_name } = useSelector(
    (state) => state.userReducer.advisor,
  );
  const accounts = useSelector((state) => state.accountReducer?.accounts?.list);

  const [hideList, setHideList] = useState(true);

  const getAccount = useMemo(() => {
    const account = (selectedAccount && accounts?.find( i => i.id === selectedAccount)) ?? {};
    return account;
  }, [selectedAccount]);

  const handleSelectAccount = (key) => {
    setSelectedAccount(key);
  };

  const toggleAccountSelect = () => {
    setHideList(!hideList);
  };

  const clientMenuItem = [
    {
      key: 1,
      label: t('OVERVIEW'),
      icon: <Owerview />,
      path: `/account/${selectedAccount}/overview`,
    },
    {
      key: 2,
      label: t('DOCUMENTS'),
      icon: <Document />,
      path: `/account/${selectedAccount}/documents`,
    },
    {
      key: 3,
      label: t('ACCOUNT_DETAILS'),
      icon: <AccountDetail />,
      path: `/account/${selectedAccount}/account-details`,
    },
    {
      key: 4,
      label: t('SUPPORT_LC'),
      icon: <SupportIcon />,
      path: '/support',
    },
    {
      key: 5,
      label: t('SETTINGS'),
      icon: <SettingsIcon />,
      path: '/settings',
    },
  ];

  const itemsNotSecure = [
    {
      key: 4,
      label: t('SUPPORT_LC'),
      icon: <SupportIcon />,
      path: '/support',
    },
    {
      key: 5,
      label: t('SETTINGS'),
      icon: <SettingsIcon />,
      path: '/settings',
    },
  ];

  const [clientMenu, setClientMenu] = useState(null);
  const menu_1 = ['account', 'account/overview'];

  useEffect(() => {
    const preCategory =
      pathname.split('/')?.length > 2
        ? `${pathname.split('/').at(1)}${pathname.split('/').at(-1) ? '/' + pathname.split('/')?.at(-1) : ''}`
        : `${pathname.split('/').at(1)}`;
    if (menu_1.includes(preCategory)) setClientMenu(1);
    if (preCategory === 'documents') setClientMenu(2);
    if (preCategory === 'support') setClientMenu(4);
    if (preCategory === 'settings') setClientMenu(5);
    if (preCategory === 'settings') setClientMenu(5);
  }, [id, pathname]);

  const getUserName = () => {
    const roleUser = t('CLIENT');
    const nameUser =
      first_name || last_name ? `${first_name} ${last_name}` : 'TEst Client';
    return { roleUser, nameUser };
  };

  const handleClientMenu = (key, path) => {
    setClientMenu(key);
    history.push(path);
  };

  const getShortName = (name) => {
    const nameShort = name?.split(' ')?.map( i => i ? i[0]?.toUpperCase() : '')?.join('').slice(0, 2) ?? '- -';
    console.log('🚀 ~ getShortName ~ nameShort:', nameShort);
    return nameShort;
  };

  const handleLogout = () => {
    dispatch(logOut());
  };

  return (
    <Sider
      id="client"
      width="258"
      className="sider-style"
      collapsible
      collapsed={collapsed}
      trigger={null}
    >
      <div className="logo-wrapper client">
        {!collapsed && (
          <NavLink to={'/'} className="logotype">
            {theme === 'dark' ? <LogoDark /> : <img src={LogoType} alt="" />}
          </NavLink>
        )}
        <Button
          type="text"
          icon={!collapsed ? <TogglerLeft /> : <TogglerRight />}
          onClick={() => setCollapsed(!collapsed)}
          className="toggle-btn"
          style={{
            fontSize: '16px',
            width: 24,
            height: 64,
          }}
        />
      </div>
      <div className="client-wrapper">
      <div>
        <div className={collapsed ? 'account-select-wrap collapsed' : 'account-select-wrap'}>
          {!collapsed && <p className="account-title">{t('ACCOUNT')}</p>}
          {accounts?.length > 1 && <div
            className={`account-title-btn ${!hideList ? 'uncollapsed' : ''} ${isLoading ? 'desabled' : ''}`}
            onClick={toggleAccountSelect}
          >
            <div className={collapsed ? 'account-select-btn  collapsed': 'account-select-btn'}>
              <Text ellipsis={true}>
                {!collapsed
                  ? getAccount?.name ?? '- - -'
                  : getShortName(getAccount?.name)}
              </Text>
              <ListCollapse />
            </div>
            {!hideList 
              ? (accounts?.map(i => {
                return (
                  <Button 
                    key={i.id}
                    type='text'
                    className='account-item' 
                    disabled={i.id === selectedAccount}
                    onClick={() => handleSelectAccount(i.id)}
                  >
                    {i.name}
                  </Button>
                );
              })
              ) 
              : null
            }
          </div>}
        </div>
        <div className="client-nav-wrapper">
          {role === 'CLIENT_NOT_SECURE'
            ? id &&
              itemsNotSecure.map((i) => {
                return collapsed ? (
                  <div
                    key={i.key}
                    onClick={() => handleClientMenu(i.key, i.path)}
                    className="menu-btn-wrap"
                  >
                    <Button
                      icon={<Checked />}
                      iconPosition="start"
                      type="text"
                      className={`client-menu-btn ${clientMenu === i.key ? 'selected' : ''}`}
                    />
                    {i.icon}
                  </div>
                ) : (
                  <div
                    key={i.key}
                    onClick={() => handleClientMenu(i.key, i.path)}
                    className="menu-btn-wrap"
                  >
                    <Button
                      icon={<Checked />}
                      iconPosition="start"
                      type="text"
                      className={`client-menu-btn ${clientMenu === i.key ? 'selected' : ''}`}
                    >
                      {i.label}
                    </Button>
                    {i.icon}
                  </div>
                );
              })
            : id &&
              clientMenuItem.map((i) => {
                return collapsed ? (
                  <div
                    key={i.key}
                    onClick={() => handleClientMenu(i.key, i.path)}
                    className="menu-btn-wrap"
                  >
                    <Button
                      icon={<Checked />}
                      iconPosition="start"
                      type="text"
                      className={`client-menu-btn ${clientMenu === i.key ? 'selected' : ''}`}
                    />
                    {i.icon}
                  </div>
                ) : (
                  <div
                    key={i.key}
                    onClick={() => handleClientMenu(i.key, i.path)}
                    className="menu-btn-wrap"
                  >
                    <Button
                      icon={<Checked />}
                      iconPosition="start"
                      type="text"
                      className={`client-menu-btn ${clientMenu === i.key ? 'selected' : ''}`}
                    >
                      {i.label}
                    </Button>
                    {i.icon}
                  </div>
                );
              })}
        </div>
      </div>
        <div className="client-bottom-box">
          {!collapsed && (
            <div className="client-box-text-wraper">
              <p className="client-text-name">{getUserName().nameUser}</p>
              <p className="client-text-role">{getUserName().roleUser}</p>
            </div>
          )}
          <div className="client-logout-link" onClick={handleLogout}>
            <Logout />
          </div>
        </div>
      </div>
    </Sider>
  );
};

export default SideBarClient;

SideBarClient.propTypes = {
  theme: PropTypes.string,
  selectedAccount: PropTypes.number,
  setSelectedAccount: PropTypes.func,
};
