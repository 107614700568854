/* eslint-disable camelcase */

import { LOG_OUT } from '../user/userActionTypes';
import {
  CLEAR_APPROVED_PORTFOLIO,
  CLEAR_EXECUTED_PORTFOLIO,
  CLEAR_IMPLEMENTED_PORTFOLIO,
  CLEAR_SUGGESTIONS_PORTFOLIO,
  RESET_ERROR,
} from '../helpers/auxiliaryActionTypes';
import {
  SET_CLIENT_TYPE,
  SET_CLIENT_INFORMATION,
  SET_CLIENT_PHONE,
  SET_CLIENT_ADDRESS,
  SET_CLIENT_MARITAL,
  SET_CLIENT_SCORE,
  SET_CLIENT_DATA,
  UPDATE_CLIENT_INFO,
  DELETE_CLIENT_PHONE,
  UPDATE_CLIENT_PHONE,
  GET_CLIENT_DATA,
  SEND_INVITATION,
  GET_INVITATION_INFO,
  ACCEPT_INVITATION,
  SET_CLIENT_RISK_LEVEL,
  SEND_CLIENT_APPROVE,
  SET_CLIENT_FEES,
  GET_CLIENT_FEES,
  ADD_REPRESENTATIVE,
  UPDATE_REPRESENTATIVE,
  UPDATE_CLIENT_INFO_CLEAR,
  CREATE_FINPLAN,
  GET_FINPLAN,
  PATCH_FINPLAN,
  GET_SUGGESTIONS_PORTFOLIO,
  DELETE_SUGGESTIONS_PORTFOLIO,
  GET_APPROVED_PORTFOLIO,
  GET_ALL_APPROVED_PORTFOLIO,
  GET_CANDIDATE_TRANSACTIONS,
  GET_ALL_EXECUTED_PORTFOLIO,
  GET_ALL_IMPLEMENTED_PORTFOLIO,
  GET_EXECUTED_PORTFOLIO,
  GET_IMPLEMENTED_PORTFOLIO,
  DOWNLOAD_PDF_REPOR,
  GET_ONDOARDING_STATUS,
  POST_ONDOARDING_DATA,
  SET_ONDOARDING_VERIFED,
  CLEAR_ONDOARDING,
  DELETE_ONDOARDING_DOCUMENT,
  GET_ONDOARDING_DOCUMENTS_LIST,
  UPLOAD_ONDOARDING_DOCUMENT,
  IMPLEMENTE_PORTFOLIO,
  RESCHEDULE_ONDOARDING,
  CREATE_ACCOUNT,
  GET_ACCOUNTS_LIST,
  GET_ACCOUNT,
  GET_RELATED_ACCOUNT,
  CREATE_CLIENT_TO_ACCOUNT,
  CLEAR_RELATED_PERSON,
  DELETE_ACCOUNT,
  CLEAR_ACCOUNT_BY_ID,
} from './accountActionsTypes';

// const initRepresentative = [
//   {
//     authority: [],
//     personal_number: '',
//     first_name: '',
//     last_name: '',
//     email: '',
//     mobile_number: [{}],
//   },
// ];

const relatedMock = {id: 32131, account_type: 'private', name: 'Test mock', description: 'some text', 
  related_persons: [{user_id: 5555, permission: 'write', isOwner: true }]};

const initialState = {
  accounts: [],
  accountById: {
    company_representive: [],
  },
  finPlan: [],
  suggestions: null,
  approved: null,
  executed: null,
  implemented: null,
  onbording: null,
  isLoading: false,
  isError: false,
  invitationInfo: '',
  transactionButSell: null,
  requestCount: 0,
  isDownload: false,
  uploadDocs: null,
  relatedUsers: null,
};

export const accountReducer = (state = initialState, action) => {
  const patchFinPlan = (data) => {
    const plan = state.finPlan;
    return plan.map((i) => (i.id === data.id ? data : i));
  };
  // const setClientBase = (data) => {
  //   const newRepresent = [];
  //   if (data.company_representive.length > 0) {
  //     data.company_representive.forEach((elem) => {
  //       const representative = { ...elem };
  //       representative.authority = elem.authority
  //         ? JSON.parse(elem.authority)
  //         : [];
  //       representative.mobile_number = elem.mobile_number
  //         ? JSON.parse(elem.mobile_number)
  //         : [];
  //       newRepresent.push(representative);
  //     });
  //     data.company_representive = newRepresent;
  //   } else {
  //     data.company_representive = initRepresentative;
  //   }
  //   if (!data?.portfolio) {
  //     data.portfolio = state.users.portfolio;
  //   }
  //   return { ...state.users, ...data };
  // };

  const deleteUser = (id) => {
    return state.accounts.list.filter((i) => i.id !== id);
  };

  switch (action.type) {
    case GET_ACCOUNTS_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case GET_ACCOUNTS_LIST.SUCCESS:
      return {
        ...state,
        accounts: action.payload,
        isError: false,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
      };

    case GET_ACCOUNTS_LIST.ERROR:
      return {
        ...state,
        isError: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
      };
    
    case GET_ACCOUNT.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_ACCOUNT.SUCCESS:
      return {
        ...state,
        // user: setClientBase(action.payload),
        accountById: action.payload,
        isLoading: false,
        isError: false,
      };

    case GET_ACCOUNT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case CREATE_CLIENT_TO_ACCOUNT.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_CLIENT_TO_ACCOUNT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case CREATE_CLIENT_TO_ACCOUNT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case CREATE_ACCOUNT.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_ACCOUNT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        accountById: action.payload,
        accounts: {
          ...state.accounts,
          list: [...state.accounts.list, action.payload],
          total_count: state.accounts.total_count + 1
        }
      };

    case CREATE_ACCOUNT.ERROR:
      return {
        ...state,
        isLoading: false,
        // isError: action.payload,
        //TODO del this
        accountById: relatedMock,
      };

    case SEND_INVITATION.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SEND_INVITATION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SEND_INVITATION.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case GET_INVITATION_INFO.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_INVITATION_INFO.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        invitationInfo: action.payload?.client?.email,
      };

    case GET_INVITATION_INFO.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case ACCEPT_INVITATION.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ACCEPT_INVITATION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case ACCEPT_INVITATION.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case SET_CLIENT_PHONE.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SET_CLIENT_PHONE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case SET_CLIENT_PHONE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case ADD_REPRESENTATIVE.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_REPRESENTATIVE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case ADD_REPRESENTATIVE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case UPDATE_REPRESENTATIVE.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_REPRESENTATIVE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case UPDATE_REPRESENTATIVE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case SET_CLIENT_TYPE.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SET_CLIENT_TYPE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: { ...state.user, type: action.payload },
      };
    case SET_CLIENT_TYPE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case UPDATE_CLIENT_INFO.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_CLIENT_INFO.SUCCESS:
      return {
        ...state,
        isLoading: false,
        // users: { list: updateUsersList(action.payload) },
        // user: updateUserData(action.payload),
        isError: false,
        showMessage: { type: 'success' },
      };

    case UPDATE_CLIENT_INFO.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
        showMessage: { type: 'error' },
      };

    case UPDATE_CLIENT_INFO_CLEAR.ACTION:
      return {
        ...state,
        showMessage: null,
      };

    case SET_CLIENT_ADDRESS.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SET_CLIENT_ADDRESS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SET_CLIENT_ADDRESS.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case SET_CLIENT_MARITAL.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SET_CLIENT_MARITAL.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SET_CLIENT_MARITAL.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case SET_CLIENT_SCORE.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SET_CLIENT_SCORE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SET_CLIENT_SCORE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };
    case SET_CLIENT_INFORMATION.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SET_CLIENT_INFORMATION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SET_CLIENT_INFORMATION.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case SET_CLIENT_DATA.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SET_CLIENT_DATA.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: {
          ...state.user,
          client_data: {
            ...state.user.client_data,
            [action.payload.type]: {
              data: action.payload.data,
              full_status: action.payload.full_status,
            },
          },
          recomented_risk_level: action.payload.risk_level,
        },
      };

    case SET_CLIENT_DATA.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case GET_CLIENT_DATA.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CLIENT_DATA.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: {
          ...state.user,
          client_data: {
            ...state.user.client_data,
            [action.payload.type]: {
              data: action.payload.data,
              full_status: action.payload.full_status,
              risk_level: action.payload.risk_level,
            },
          },
        },
      };

    case GET_CLIENT_DATA.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case UPDATE_CLIENT_PHONE.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_CLIENT_PHONE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case UPDATE_CLIENT_PHONE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case DELETE_CLIENT_PHONE.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_CLIENT_PHONE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case DELETE_CLIENT_PHONE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case SET_CLIENT_RISK_LEVEL.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SET_CLIENT_RISK_LEVEL.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SET_CLIENT_RISK_LEVEL.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case SEND_CLIENT_APPROVE.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SEND_CLIENT_APPROVE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SEND_CLIENT_APPROVE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case SET_CLIENT_FEES.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SET_CLIENT_FEES.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SET_CLIENT_FEES.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case GET_CLIENT_FEES.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CLIENT_FEES.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: {
          ...state.user,
          fees: action.payload,
        },
      };

    case GET_CLIENT_FEES.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case DELETE_ACCOUNT.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_ACCOUNT.SUCCESS:
      return {
        ...state,
        accounts: {
          total_count: state.accounts.total_count - 1,
          list: deleteUser(action.payload),
        },
        isLoading: false,
      };

    case DELETE_ACCOUNT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case CREATE_FINPLAN.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_FINPLAN.SUCCESS:
      return {
        ...state,
        finPlan: [...state.finPlan, action.payload],
        isLoading: false,
      };

    case CREATE_FINPLAN.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case GET_FINPLAN.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_FINPLAN.SUCCESS:
      return {
        ...state,
        finPlan: action.payload,
        isLoading: false,
      };

    case GET_FINPLAN.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case PATCH_FINPLAN.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case PATCH_FINPLAN.SUCCESS:
      return {
        ...state,
        finPlan: patchFinPlan(action.payload),
        isLoading: false,
      };

    case PATCH_FINPLAN.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case GET_SUGGESTIONS_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_SUGGESTIONS_PORTFOLIO.SUCCESS:
      return {
        ...state,
        suggestions: action.payload,
        isLoading: false,
      };

    case GET_SUGGESTIONS_PORTFOLIO.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case GET_APPROVED_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_APPROVED_PORTFOLIO.SUCCESS:
      return {
        ...state,
        approved: action.payload,
        isLoading: false,
      };

    case GET_APPROVED_PORTFOLIO.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case GET_EXECUTED_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case GET_EXECUTED_PORTFOLIO.SUCCESS:
      return {
        ...state,
        executed: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
      };

    case GET_EXECUTED_PORTFOLIO.ERROR:
      return {
        ...state,
        isError: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
      };

    case GET_IMPLEMENTED_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case GET_IMPLEMENTED_PORTFOLIO.SUCCESS:
      return {
        ...state,
        implemented: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
      };

    case GET_IMPLEMENTED_PORTFOLIO.ERROR:
      return {
        ...state,
        isError: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
      };

    case IMPLEMENTE_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case IMPLEMENTE_PORTFOLIO.SUCCESS:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
      };

    case IMPLEMENTE_PORTFOLIO.ERROR:
      return {
        ...state,
        isError: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
      };

    case GET_ALL_APPROVED_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case GET_ALL_APPROVED_PORTFOLIO.SUCCESS:
      return {
        ...state,
        approved: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
      };

    case GET_ALL_APPROVED_PORTFOLIO.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case GET_ALL_EXECUTED_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case GET_ALL_EXECUTED_PORTFOLIO.SUCCESS:
      return {
        ...state,
        executed: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
      };

    case GET_ALL_EXECUTED_PORTFOLIO.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case GET_ALL_IMPLEMENTED_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case GET_ALL_IMPLEMENTED_PORTFOLIO.SUCCESS:
      return {
        ...state,
        implemented: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
      };

    case GET_ALL_IMPLEMENTED_PORTFOLIO.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case GET_CANDIDATE_TRANSACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case GET_CANDIDATE_TRANSACTIONS.SUCCESS:
      return {
        ...state,
        transactionButSell: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
      };

    case GET_CANDIDATE_TRANSACTIONS.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case DELETE_SUGGESTIONS_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_SUGGESTIONS_PORTFOLIO.SUCCESS:
      return {
        ...state,
        // suggestions: action.payload,
        isLoading: false,
      };

    case DELETE_SUGGESTIONS_PORTFOLIO.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case DOWNLOAD_PDF_REPOR.REQUEST:
      return {
        ...state,
        isDownload: true,
      };

    case DOWNLOAD_PDF_REPOR.SUCCESS:
      return {
        ...state,
        isDownload: false,
      };

    case DOWNLOAD_PDF_REPOR.ERROR:
      return {
        ...state,
        isDownload: false,
        isError: action.payload,
      };

    case GET_ONDOARDING_STATUS.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_ONDOARDING_STATUS.SUCCESS:
      return {
        ...state,
        onbording: action.payload,
        isLoading: false,
      };

    case GET_ONDOARDING_STATUS.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case POST_ONDOARDING_DATA.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case POST_ONDOARDING_DATA.SUCCESS:
      return {
        ...state,
        onbording: action.payload,
        isLoading: false,
      };

    case POST_ONDOARDING_DATA.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case RESCHEDULE_ONDOARDING.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case RESCHEDULE_ONDOARDING.SUCCESS:
      return {
        ...state,
        onbording: action.payload,
        isLoading: false,
      };

    case RESCHEDULE_ONDOARDING.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case SET_ONDOARDING_VERIFED.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SET_ONDOARDING_VERIFED.SUCCESS:
      return {
        ...state,
        onbording: action.payload,
        isLoading: false,
      };

    case SET_ONDOARDING_VERIFED.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case DELETE_ONDOARDING_DOCUMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_ONDOARDING_DOCUMENT.SUCCESS:
      return {
        ...state,
        uploadDocs: action.payload,
        isLoading: false,
      };

    case DELETE_ONDOARDING_DOCUMENT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case UPLOAD_ONDOARDING_DOCUMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UPLOAD_ONDOARDING_DOCUMENT.SUCCESS:
      return {
        ...state,
        uploadDocs: action.payload,
        isLoading: false,
      };

    case UPLOAD_ONDOARDING_DOCUMENT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case GET_ONDOARDING_DOCUMENTS_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_ONDOARDING_DOCUMENTS_LIST.SUCCESS:
      return {
        ...state,
        uploadDocs: action.payload,
        isLoading: false,
      };

    case GET_ONDOARDING_DOCUMENTS_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case GET_RELATED_ACCOUNT.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_RELATED_ACCOUNT.SUCCESS:
      return {
        ...state,
        relatedUsers: action.payload,
        isLoading: false,
      };

    case GET_RELATED_ACCOUNT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case CLEAR_SUGGESTIONS_PORTFOLIO:
      return {
        ...state,
        suggestions: null,
        isError: false,
      };

    case CLEAR_APPROVED_PORTFOLIO:
      return {
        ...state,
        approved: null,
        isError: false,
      };

    case CLEAR_EXECUTED_PORTFOLIO:
      return {
        ...state,
        executed: null,
        isError: false,
      };

    case CLEAR_IMPLEMENTED_PORTFOLIO:
      return {
        ...state,
        implemented: null,
        isError: false,
      };

    case CLEAR_ONDOARDING.ACTION:
      return {
        ...state,
        onbording: null,
        isError: false,
      };

    case CLEAR_RELATED_PERSON.ACTION:
      return {
        ...state,
        relatedUsers: null,
        isError: false,
      };

    case CLEAR_ACCOUNT_BY_ID.ACTION:
      return {
        ...state,
        relatedUsers: null,
        accountById: {
          company_representive: [],
        },
        isError: false,
      };

    case LOG_OUT:
      return {
        users: [],
        isLoading: false,
        isError: false,
      };

    case RESET_ERROR:
      return {
        ...state,
        isError: false,
      };

    default: {
      return state;
    }
  }
};
