import {
  SET_CLIENT_TYPE,
  SET_CLIENT_INFORMATION,
  SET_CLIENT_ADDRESS,
  SET_CLIENT_SCORE,
  SET_CLIENT_PHONE,
  UPDATE_CLIENT_PHONE,
  DELETE_CLIENT_PHONE,
  UPDATE_CLIENT_INFO,
  SET_CLIENT_DATA,
  GET_CLIENT_DATA,
  SEND_INVITATION,
  GET_INVITATION_INFO,
  ACCEPT_INVITATION,
  SET_CLIENT_RISK_LEVEL,
  SEND_CLIENT_APPROVE,
  SET_CLIENT_FEES,
  GET_CLIENT_FEES,
  GET_REPRESENTATIVE,
  ADD_REPRESENTATIVE,
  UPDATE_REPRESENTATIVE,
  DELETE_REPRESENTATIVE,
  ADD_REPRESENTATIVE_DOC,
  CREATE_FINPLAN,
  GET_FINPLAN,
  PATCH_FINPLAN,
  DELETE_FINPLAN,
  GET_SUGGESTIONS_PORTFOLIO,
  APPROVE_SUGGESTIONS_PORTFOLIO,
  DELETE_SUGGESTIONS_PORTFOLIO,
  GET_APPROVED_PORTFOLIO,
  GET_ALL_APPROVED_PORTFOLIO,
  EXECUTE_PORTFOLIO,
  GET_CANDIDATE_TRANSACTIONS,
  GET_EXECUTED_PORTFOLIO,
  GET_ALL_EXECUTED_PORTFOLIO,
  GET_ALL_IMPLEMENTED_PORTFOLIO,
  GET_IMPLEMENTED_PORTFOLIO,
  DOWNLOAD_PDF_REPOR,
  GET_ONDOARDING_STATUS,
  POST_ONDOARDING_DATA,
  DEL_ONDOARDING,
  SET_ONDOARDING_VERIFED,
  POST_ONDOARDING_AUDIO,
  CLEAR_ONDOARDING,
  UPLOAD_ONDOARDING_DOCUMENT,
  GET_ONDOARDING_DOCUMENTS_LIST,
  DELETE_ONDOARDING_DOCUMENT,
  UPLOAD_ONDOARDING_AUDIO,
  IMPLEMENTE_PORTFOLIO,
  RESCHEDULE_ONDOARDING,
  CREATE_ACCOUNT,
  GET_ACCOUNTS_LIST,
  GET_ACCOUNT,
  GET_RELATED_ACCOUNT,
  ADD_RELATED_PERSON,
  CREATE_CLIENT_TO_ACCOUNT,
  DELETE_RELATED_PERSON,
  CLEAR_RELATED_PERSON,
  UPDATE_RELATED_PERSON,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
  CLEAR_ACCOUNT_BY_ID,
} from './accountActionsTypes';

export const createAccount = (data) => ({
  type: CREATE_ACCOUNT.ACTION,
  payload: data
});

export const getAccountsList = () => ({
  type: GET_ACCOUNTS_LIST.ACTION
});

export const getAccount = (data) => ({
  type: GET_ACCOUNT.ACTION,
  payload: data
});

export const updateAccount = (data) => ({
  type: UPDATE_ACCOUNT.ACTION,
  payload: data
});

export const getRelatedAccount = (id) => ({
  type: GET_RELATED_ACCOUNT.ACTION,
  payload: id,
});

export const addRelatedPerson = (data) => ({
  type: ADD_RELATED_PERSON.ACTION,
  payload: data,
});

export const updateRelatedPerson = (data) => ({
  type: UPDATE_RELATED_PERSON.ACTION,
  payload: data,
});

export const deleteRelatedPerson = (data) => ({
  type: DELETE_RELATED_PERSON.ACTION,
  payload: data,
});

export const createClientToAccount = (data) => ({
  type: CREATE_CLIENT_TO_ACCOUNT.ACTION,
  payload: data
});
export const setClientType = (data) => ({
  type: SET_CLIENT_TYPE.ACTION,
  payload: data
});
export const setClientInformation = (data) => ({
  type: SET_CLIENT_INFORMATION.ACTION,
  payload: data
});
export const updateClientInfo = (data) => ({
  type: UPDATE_CLIENT_INFO.ACTION,
  payload: data
});
export const addClientPhone = (data) => ({
  type: SET_CLIENT_PHONE.ACTION,
  payload: data
});
export const updateClientPhone = (data) => ({
  type: UPDATE_CLIENT_PHONE.ACTION,
  payload: data
});
export const deleteClientPhone = (data) => ({
  type: DELETE_CLIENT_PHONE.ACTION,
  payload: data
});
export const getRepresentative = (clientID) => ({
  type: GET_REPRESENTATIVE.ACTION,
  payload: clientID
});
export const addRepresentative = (data) => ({
  type: ADD_REPRESENTATIVE.ACTION,
  payload: data
});
export const updateRepresentative = (data) => ({
  type: UPDATE_REPRESENTATIVE.ACTION,
  payload: data
});
export const deleteRepresentative = (data) => ({
  type: DELETE_REPRESENTATIVE.ACTION,
  payload: data
});

export const addRepresentativeDoc = (data) => ({
  type: ADD_REPRESENTATIVE_DOC.ACTION,
  payload: data
});

export const setClientAddress = (data) => ({
  type: SET_CLIENT_ADDRESS.ACTION,
  payload: data
});
export const setClientMaritalStatus = (data) => ({
  type: SET_CLIENT_ADDRESS.ACTION,
  payload: data
});
export const setClientScore = (data) => ({
  type: SET_CLIENT_SCORE.ACTION,
  payload: data
});
export const setClientData = (data) => ({
  type: SET_CLIENT_DATA.ACTION,
  payload: data
});
export const getClientData = (data) => ({
  type: GET_CLIENT_DATA.ACTION,
  payload: data
});
export const sendInvitation = (data) => ({
  type: SEND_INVITATION.ACTION,
  payload: data
});
export const getInvitationInfo = (data) => ({
  type: GET_INVITATION_INFO.ACTION,
  payload: data
});
export const acceptInvitation = (data) => ({
  type: ACCEPT_INVITATION.ACTION,
  payload: data
});
export const setRiskLevel = (data) => ({
  type: SET_CLIENT_RISK_LEVEL.ACTION,
  payload: data
});
export const sendClientApprove = (data) => ({
  type: SEND_CLIENT_APPROVE.ACTION,
  payload: data
});
export const setClientFees = (data) => ({
  type: SET_CLIENT_FEES.ACTION,
  payload: data
});
export const getClientFees = (id) => ({
  type: GET_CLIENT_FEES.ACTION,
  payload: id
});
export const deleteAccount = (id, afterDel) => ({
  type: DELETE_ACCOUNT.ACTION,
  payload: { id, afterDel }
});
export const createFinPlan = (data) => ({
  type: CREATE_FINPLAN.ACTION,
  payload: data
});
export const getFinPlan = (clientId) => ({
  type: GET_FINPLAN.ACTION,
  payload: clientId
});
export const patchFinPlan = (data) => ({
  type: PATCH_FINPLAN.ACTION,
  payload: data
});
export const deleteFinPlan = (data) => ({
  type: DELETE_FINPLAN.ACTION,
  payload: data
});
export const getSuggestionsPortfolio = (userId) => ({
  type: GET_SUGGESTIONS_PORTFOLIO.ACTION,
  payload: userId,
});
export const approveSuggestions = (data) => ({
  type: APPROVE_SUGGESTIONS_PORTFOLIO.ACTION,
  payload: data,
});
export const deleteSuggestions = (data) => ({
  type: DELETE_SUGGESTIONS_PORTFOLIO.ACTION,
  payload: data,
});
export const getApprovedPortfolio = (data) => ({
  type: GET_APPROVED_PORTFOLIO.ACTION,
  payload: data,
});
export const getExecutedPortfolio = (data) => ({
  type: GET_EXECUTED_PORTFOLIO.ACTION,
  payload: data,
});
export const getImplementedPortfolio = (data) => ({
  type: GET_IMPLEMENTED_PORTFOLIO.ACTION,
  payload: data,
});
export const getAllApprovedPortfolio = () => ({
  type: GET_ALL_APPROVED_PORTFOLIO.ACTION,
});
export const getAllExecutedPortfolio = () => ({
  type: GET_ALL_EXECUTED_PORTFOLIO.ACTION,
});
export const getAllImplementedPortfolio = () => ({
  type: GET_ALL_IMPLEMENTED_PORTFOLIO.ACTION,
});
export const getCandidatTransactions = (data) => ({
  type: GET_CANDIDATE_TRANSACTIONS.ACTION,
  payload: data,
});
export const executeApprovedPortfolio = (data) => ({
  type: EXECUTE_PORTFOLIO.ACTION,
  payload: data,
});
export const implementePortfolio = (data) => ({
  type: IMPLEMENTE_PORTFOLIO.ACTION,
  payload: data,
});
export const downloadPdfReport = (data) => ({
  type: DOWNLOAD_PDF_REPOR.ACTION,
  payload: data,
});
export const getOnboardingStatus = (clientId) => ({
  type: GET_ONDOARDING_STATUS.ACTION,
  payload: clientId,
});
export const postOnboardingData = (data) => ({
  type: POST_ONDOARDING_DATA.ACTION,
  payload: data,
});
export const rescheduleOnboarding = (data) => ({
  type: RESCHEDULE_ONDOARDING.ACTION,
  payload: data,
});
export const delOnboarding = (id) => ({
  type: DEL_ONDOARDING.ACTION,
  payload: id,
});
export const setOnboardingVerifed = (data) => ({
  type: SET_ONDOARDING_VERIFED.ACTION,
  payload: data,
});
export const postOnboardingAudio = (data) => ({
  type: POST_ONDOARDING_AUDIO.ACTION,
  payload: data,
});
export const clearOnboarding = () => ({
  type: CLEAR_ONDOARDING.ACTION,
});
export const clearRelatedPerson = () => ({
  type: CLEAR_RELATED_PERSON.ACTION,
});
export const uploadOnboardingDocument = (data) => ({
  type: UPLOAD_ONDOARDING_DOCUMENT.ACTION,
  payload: data,
});
export const getOnboardingDocumentsList = (data) => ({
  type: GET_ONDOARDING_DOCUMENTS_LIST.ACTION,
  payload: data,
});
export const deleteOnboardingDocument = (data) => ({
  type: DELETE_ONDOARDING_DOCUMENT.ACTION,
  payload: data,
});
export const uploadOnboardingAudio = (data) => ({
  type: UPLOAD_ONDOARDING_AUDIO.ACTION,
  payload: data,
});

export const clearAccountById = () => ({
  type: CLEAR_ACCOUNT_BY_ID.ACTION,
});
