/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import {
  activateAdvisor,
  deactivateAdvisor,
  deleteAdvisor,
  getAdvisors,
  getUsersForAdvisor,
  reinviteAdvisor,
} from '../../data/store/advisors/advisorAction';

import { Row, Button, Table, Col, Layout, Modal } from 'antd';

import { ReactComponent as Filter } from '../../assets/filter-icon.svg';
import Container from '../../components/Container';
import CustomSearchInput from '../../components/CustomSearchInput/CustomSearchInput';

import { ReactComponent as Edit } from '../../assets/edit-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';

import { textSort } from '../../helpers/textSort';
import ModalFilterUsers from '../../components/ModalFilterUsers/ModalFilterUsers';

import './style.scss';

const { Header } = Layout;
const RoleList = {
  'CLIENT': 'CLIENT_ROLE',
  'CLIENT_NOT_SECURE': 'CLIENT_ROLE',
  'SUPERADMIN': 'SUPERADMIN_ROLE',
  'ADVISER': 'ADVISER_ROLE',
  'ADVISER_NOT_SECURE': 'ADVISER_ROLE',
};

const Users = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const history = useHistory();

  const users = useSelector((state) => state.advisorReducer?.users?.list);

  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(10);

  const [dataPagination, setDataPagination] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [filter, setFilter] = useState();
  const [openFilter, setOpenFilter] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    dispatch(getAdvisors({ limit: -1, offset: 0 }));
  }, []);

  useEffect(() => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const dataP = data?.slice(startIndex, endIndex);
    setDataPagination(dataP);
  }, [data]);

  const getAdvisorsPagination = useCallback(
    (page, pageSize) => {
      setPage(page);
      setPageSize(pageSize);
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const dataP = data.slice(startIndex, endIndex);
      setDataPagination(dataP);
    },
    [data]
  );

  useEffect(() => {
    setData(users?.sort((a,b) => textSort(a.first_name + ' ' + a.last_name, b.first_name + ' ' + b.last_name)));
  }, [users]);

  useEffect(() => {
    if(filter) {
      const filteredUsers = users.filter(i => (filter.role.includes(i.role) || filter.role.includes('All')) &&
      (filter.status.includes(i.status) || filter.status.includes('All')));
      console.log('🚀 ~ useEffect ~ filteredUsers:', filteredUsers);
      setData(filteredUsers);
      setPage(1);
    }
  }, [filter]);

  const handlDeactivate = (key) => {
    dispatch(deactivateAdvisor(key));
  };

  const handlActivate = (key) => {
    dispatch(activateAdvisor({ id: key, history: null }));
  };

  const handlReSendInvite = (id) => {
    dispatch(reinviteAdvisor(id));
  };
  const handlApproval = (key) => {
    dispatch(activateAdvisor({ id: key, history: null }));
  };

  const hendleEdit = (id) => {
    history.push(`/users/edit-user/${id}`);
  };

  const getAdvisorNAme = (id) => {
    const find = users?.find(i => i.id === id);
    if (find) {
      return `${find?.first_name} ${find?.last_name}`;
    } else {
      return '';
    }
  };

  const handleDeleteMOdal = (id) => {
    setDeleteId(id);
    setOpenModalDelete(true);
    console.log('handleDelete');
  };

  const cancelWithoutDelete = () => {
    setDeleteId(null);
    setOpenModalDelete(false);
  };

  const onSubmitDelete = () => {
    console.log('handleDelete');
    dispatch(deleteAdvisor(deleteId));
    cancelWithoutDelete();
    setDeleteId(null);
  };

  const getStatusStyle = (value) => {
    if (value === 'USER_STATUS_AWAIT_APPROVE') return 'yellow';
    if (value === 'USER_STATUS_ACTIVE') return 'green';
    if (value === 'USER_STATUS_DEACTIVATED') return 'red';
    if (value === 'USER_STATUS_DEACTIVATED') return 'red';
    if (value === 'USER_STATUS_RESET_PASSWORD_CODE_SENT') return 'purple';
    return 'purple';
  };

  const getStatusAction = (value) => {
    if (value === 'USER_STATUS_AWAIT_APPROVE') {
      return {
        action: handlApproval,
        title: t('APPROVE'),
        style: 'approve',
      };
    }
    if (value === 'USER_STATUS_ACTIVE') {
      return {
        action: handlDeactivate,
        title: t('DEACTIVATE'),
        style: 'deactivate',
      };
    }
    if (value === 'USER_STATUS_DEACTIVATED') {
      return {
        action: handlActivate,
        title: t('ACTIVATE'),
        style: 'active',
      };
    }
    if (value === 'USER_STATUS_RESET_PASSWORD_CODE_SENT') {
      return {
        action: handlReSendInvite,
        title: t('RE_INVITE'),
        style: 'active',
      };
    }
    return null;
  };

  const columns = [
    {
      title: t('NAME'),
      dataIndex: 'first_name',
      className: 'name',
      width: '15%',
      render: (value, record) => `${value} ${record?.last_name}`,
      sorter: (a, b) => textSort(a.first_name + ' ' + a.last_name, b.first_name + ' ' + b.last_name),
    },
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      width: '10%',
      sorter: (a, b) => textSort(a.email, b.email),
    },
    {
      title: t('MOBILE'),
      dataIndex: 'phones',
      width: '15%',
      render: (value) => (
        value?.map(
          (i) => (
            <span key={uuidv4()} style={{ display: 'block', marginBottom: '3px' }}>
              {i.country_code} {i.phone}
            </span>
          )
        ) || ''
      ),
    },
    {
      title: t('ROLE_HEADER'),
      dataIndex: 'role',
      width: '10%',
      render: (value) => t(RoleList[value]),
      sorter: (a, b) => textSort(a.role, b.role),
    },
    {
      title: t('STATUS'),
      dataIndex: 'status',
      width: '15%',
      render: (value) => {
        return (
          <span className={`status-advisor ${getStatusStyle(value)}`}>
            {value === 'USER_STATUS_AWAIT_APPROVE'
              ? t('AWAITING_APPROVAL')
              : value === 'USER_STATUS_ACTIVE'
                ? t('ACTIVE')
                : value === 'USER_STATUS_DEACTIVATED'
                  ? t('NOT_ACTIVE')
                  : t('NOT_ACTIVATED_YET')}
          </span>
        );
      },
      sorter: (a, b) => textSort(getStatusStyle(a.status), getStatusStyle(b.status)),
    },
    {
      title: t('ACTIONS'),
      dataIndex: 'operation',
      width: '20%',
      render: (_, record) => {
        return (
          <div
            className="cell-button-wrapper"
            style={{ justifyContent: 'flex-start' }}
          >
            {getStatusAction(record.status) && (
              <Button
                className={`action-btn advisor-action-btn ${
                  getStatusAction(record.status)?.style
                }`}
                onClick={() =>
                  getStatusAction(record.status)?.action(record.id)
                }
              >
                {getStatusAction(record.status)?.title}
              </Button>
            )}
            <Button type="text" onClick={() => hendleEdit(record.id)}>
              <Edit />
            </Button>
            {(getStatusStyle(record.status) === 'red' || getStatusStyle(record.status) === 'purple') && (
              <Button type="text" onClick={() => handleDeleteMOdal(record.id)}>
                <Delete />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const handleSearch = (event) => {
    const { value } = event.target;
    if (value?.length > 2) {
      const searched = users?.filter(
        (i) =>
          (i.first_name + ' ' + i.last_name)
            .toLowerCase()
            .includes(value?.toLowerCase()) ||
          i.ISIN?.toLowerCase().includes(value?.toLowerCase())
      );
      setData(searched);
      setPage(1);
    } else {
      setData(users);
      setPage(1);
    }
  };

  const handeleFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleCreate = () => {
    history.push('/users/invite-advisor');
  };

  return (
    <Row className="advisor-wrapper">
      <div className="top-btn-wrapper">
        <Button
          onClick={handleCreate}
          className="portfolio-details-btn optimise-btn">
          {t('INVITE_USER')}
        </Button>
      </div>
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="text" className="overview-btn ant-btn-active">
              {t('USERS')}
            </Button>
          </Col>
        </Row>
      </Header>
      <Container className="advisor-container table-inner-wrapper" widthP={100} >
        <Row
          justify={'space-between'}
          align={'middle'}
          style={{ marginBottom: '19px', padding: '19px 40px 0' }}
        >
          <Col>
            <h2 className="instrument-header-title">{t('USERS_LIST')}</h2>
          </Col>
          <Col className="instrument-button-wraper">
            <CustomSearchInput
              placeholder={t('SEARCH_LC')}
              onChange={handleSearch}
            />
            <Button
              className="filter-btn"
              type="default"
              onClick={handeleFilter}
              icon={<Filter />}
              iconPosition="end"
            >
              {t('FILTER')}
              {filter && data?.length
                ? (
                <span className="filter-counter">{data?.length}</span>
                  )
                : (
                    ''
                  )}
            </Button>
          </Col>
        </Row>
        <Table
          rowClassName={(_, index) =>
            index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
          }
          style={{ width: '100%' }}
          bordered
          dataSource={dataPagination}
          columns={columns}
          pagination={{
            showSizeChanger: true,
            onChange: getAdvisorsPagination,
            total: data?.length ?? 0,
            current: page,
          }}
        />
      </Container>
      <Modal
        width={650}
        className="modal-content-paper"
        open={openModalDelete}
        onCancel={() => setOpenModalDelete(false)}
        footer={null}
        zIndex={1600}>
        <Row justify="center">
          <Col className="modal-title">{t('MODAL_DELETE_TITLE')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col>
            <p className="modal-advisor-text">{t('MODAL_DELETE_TEXT')}</p>
            <p className="modal-advisor-name">{getAdvisorNAme(deleteId)}</p>
          </Col>
        </Row>
        <Row className="modal-advisor-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={cancelWithoutDelete}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn delete-btn" onClick={onSubmitDelete}>
            {t('DELETE')}
          </Button>
        </Row>
      </Modal>
      <ModalFilterUsers
        setFilter={setFilter}
        openFilter={openFilter}
        handleCloseFilter={handleCloseFilter}
      />
    </Row>
  );
};

export default Users;
