/* eslint-disable camelcase */
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { Button, Col, Modal, Row } from 'antd';
import TableBuySell from './TableBuySell';

const ModalViewTransaction = ({
  openModalTransaction,
  closeModalTransaction,
}) => {
  const { t } = useTranslation('');

  const [tab, setTab] = useState('buy');

  const buy = useSelector(
    (state) => state.accountReducer.transactionButSell?.buy
  );
  const sell = useSelector(
    (state) => state.accountReducer.transactionButSell?.sell
  );
  const setId = useSelector(
    (state) => state.accountReducer.transactionButSell?.id
  );
  const { isLoading } = useSelector((state) => state.accountReducer);
  const { currencyList } = useSelector((state) => state.instrumentsReducer);

  const getCurrencyName = (id) => {
    return currencyList?.find( i => i.id === id)?.name ?? '';
  };

  const handleTabsSelect = (tag) => {
    setTab(tag);
  };

  const handleClose = () => {
    setTab('buy');
    closeModalTransaction();
  };

  return (
    <Modal
      width={1200}
      className="modal-translation"
      open={openModalTransaction}
      onCancel={handleClose}
      destroyOnClose={true}
      zIndex={1600}
      footer={null}
    >

          <Row justify={'start'} style={{ borderBottom: '1px solid #E1E1E9' }}>
            <Col className="tabs-tab-wrapper">
              <Button
                type="text"
                className={`overview-btn ${
                  tab === 'buy' ? 'ant-btn-active' : ''
                }`}
                onClick={() => handleTabsSelect('buy')}
              >
                {t('BUY')}
              </Button>
              <Button
                type="text"
                className={`overview-btn ${
                  tab === 'sell' ? 'ant-btn-active' : ''
                }`}
                onClick={() => handleTabsSelect('sell')}
              >
                {t('SELL')}
              </Button>
            </Col>
          </Row>
          <div className="transaction-table-wrap">
              <TableBuySell
                tab={tab}
                setId={setId}
                dataBuySell={tab === 'buy' ? buy || [] : sell || []}
                isLoading={isLoading}
                currencyName={getCurrencyName}
              />
          </div>
    </Modal>
  );
};

export default ModalViewTransaction;

ModalViewTransaction.propTypes = {
  openModalTransaction: PropTypes.bool,
  closeModalTransaction: PropTypes.func,
};
