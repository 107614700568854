import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getAdvisorById,
  getQRCodeAttachBankID,
  getQRCodeSignBankID,
} from '../../../data/store/user/userActions';
import axios from 'axios';
import { API_URLS } from '../../../constants/api_urls';
import ModalVerifyPersonalNumber from '../../../components/ModalVerifyPersonalNumber/ModalVerifyPersonalNumber';

// import { ReactComponent as BanckID } from '../../../assets/banckId-icon.svg';
import banckID from '../../../assets/bankID-icon.png';
import './style.scss';

const BanckID = () => {
  return (
    <div className='banckID-img'><img src={banckID} alt=''/></div>
  );
};

const PersonalNumberRow = ({
  value,
  successAlert,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const [, forceUpdate] = useState({});
  const [pnumber, setPnumber] = useState(null);
  const [openBankId, setOpenBankId] = useState(false);
  const [codeQR, setCodeQR] = useState('');
  const id = useSelector((state) => state.userReducer.advisor.id);
  const { codeBankID } = useSelector((state) => state.userReducer);

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (codeBankID?.qrs?.length > 0 && openBankId) {
      renderCode();
    }
  }, [codeBankID, openBankId]);

  const [intervalID, setIntervalID] = useState(null);

  const stopInterval = (idInt) => {
    clearInterval(idInt);
    setIntervalID(null);
  };

  const verifyByBankId = () => {
    if (pnumber && id) {
      dispatch(getQRCodeAttachBankID(pnumber));
      setOpenBankId(true);
    }
  };

  const collect = async () => {
    const resp = await axios.get(
      `${API_URLS.SIGN_COLLECT_ATTACH}?order_ref=${codeBankID.order_ref}`
    );
    let data;
    resp.status === 200 ? (data = resp.data) : (data = { status: 'error' });
    return data;
  };
  const stopCollect = async () => {
    const resp = await axios.post(`${API_URLS.BANKID_CANCEL_COLLECT}`, {
      order_ref: codeBankID.order_ref,
    });
    return resp.data;
  };

  const renderCode = () => {
    const offset = Math.round(
      (Date.now() - codeBankID.start_time / 1000) / 1000
    );
    let startIndex = offset;
    const idInt = setInterval(code, 1000);
    setIntervalID(idInt);

    async function code () {
      if (startIndex > codeBankID.qrs?.length - 1 || !openBankId) {
        dispatch({ type: 'CLEAR_QR_BANKID' });
        stopInterval(idInt);
        setIntervalID(null);
        !openBankId ? setCodeQR('') : setCodeQR('Try again');
        if (openBankId) {
          successAlert({ value: t('ALERT_ERROR'), type: 'error' });
        }
      } else if (codeBankID?.qrs?.length) {
        const res = await collect();
        if (res?.hint_code === 'userSign') {
          setCodeQR('wait finger');
        } else if (!res?.hint_code && res?.status === 'complete') {
          successAlert({ value: t('ALERT_ERROR'), type: 'success' });
          stopInterval(idInt);
          setIntervalID(null);
          setOpenBankId(false);
          setCodeQR('');
          dispatch(getAdvisorById());
        } else if (res?.status === 'forbiden' || res?.status === 'error') {
          successAlert({ value: t('ALERT_ERROR'), type: 'error' });
          stopInterval(idInt);
          setIntervalID(null);
          setOpenBankId(false);
          setCodeQR('');
        } else setCodeQR(codeBankID.qrs[startIndex]);
        startIndex += 1;
      }
    }
  };
  const closeBankID = () => {
    setOpenBankId(false);
    setCodeQR('');
    if (codeBankID.order_ref) stopCollect();
    clearInterval(intervalID);
    setIntervalID(null);
    dispatch({ type: 'CLEAR_QR_BANKID' });
  };
  const restartSign = () => {
    if (!intervalID) dispatch(getQRCodeSignBankID(pnumber));
  };

  const hendleChangeValue = (event) => {
    const { value } = event.target;
    setPnumber(value);
  };

  return (
    <>
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ maxWidth: '1128px', marginBottom: '0', padding: '0' }}
      >
        <Col style={{ display: 'flex', alignItems: 'center', gap: '48px' }}>
          <h2
            className="client setting-header-title"
            >{t('PERSONAL_NUMBER')}</h2>
          <Form.Item
            style={{marginBottom: '0'}}
            className='custom-required'
            maxLength={50}
            required={false}
          >
          <Input
            disabled={value}
            value={value || pnumber}
            onChange={hendleChangeValue}
            className='client custom-input'
          />
          </Form.Item>
        </Col>

        {!value && (
          <Button
            type='text'
            icon={<BanckID/>}
            iconPosition='start'
            onClick={verifyByBankId}
            className={
              pnumber ? 'client profile-bankid-btn' : 'client profile-bankid-btn disabled'
            }>
            {t('CONFIRM_BANKID')}
          </Button>
        )}
      </Row>
      <ModalVerifyPersonalNumber
        closeBankID={closeBankID}
        codeQR={codeQR}
        openBankId={openBankId}
        restartSign={restartSign}
      />
    </>
  );
};

export default PersonalNumberRow;

PersonalNumberRow.propTypes = {
  value: PropTypes.string,
  handleDeleteValue: PropTypes.func,
  successAlert: PropTypes.func,
};
