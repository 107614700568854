/* eslint-disable camelcase */
import React, { useEffect, useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import {
  deleteInstrument,
  getCurrency,
  getDiversification,
  getInstrumentsAll,
  getInstrumentsTBD,
  // updateInstrument,
} from '../../data/store/instruments/instrumentsActions';

import { Button, Col, Layout, Modal, Row, } from 'antd';

import { formatPercentage2 } from '../../helpers/formatPercentage2';
import { numberWithSpaces } from '../../helpers/formatInteger';

import Instruments from '../../components/Instruments/Instruments';
import InstrumentsTBD from '../../components/InstrumentsTBD/InstrumentsTBD';
import Container from '../../components/Container';
import MainFooter from '../../components/MainFooter';
import { ReactComponent as Edit } from '../../assets/edit-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';

import './style.scss';
import { textSort } from '../../helpers/textSort';

const { Header } = Layout;
export const AssetTypes = {
  ETF: 1,
  Certificate: 2,
  Fund: 3,
  Equity: 4,
  Other: 5,
  Unknown: 6,
};

// eslint-disable-next-line no-unused-vars
export const AssetTypeLabels = {
  [AssetTypes.ETF]: 'ETF',
  [AssetTypes.Certificate]: 'CERT',
  [AssetTypes.Fund]: 'FUND',
  [AssetTypes.Equity]: 'EQUITY',
  [AssetTypes.Other]: 'OTHER',
  [AssetTypes.Unknown]: 'UNKNOWN',
};

const AdminInstruments = () => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();

  const { assetTab } = useParams();

  const instruments = useSelector((state) => state.instrumentsReducer.instruments.list);
  const { currencyList } = useSelector((state) => state.instrumentsReducer);
  // const assets = useSelector((state) => state.instrumentsReducer.assets);
  const { diversificationList } = useSelector((state) => state.instrumentsReducer);

  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [daleteKey, setDaleteKey] = useState(null);

  const [sorter, setSorter] = useState('byName');
  const [sortedList, setSortedList] = useState([]);


  const getDeletingInstrument = useMemo(() => {
    if (daleteKey) {
      return sortedList.find((i) => i.ID === daleteKey);
    }
  }, [sortedList, daleteKey]);
  useEffect(() => {
    dispatch(getInstrumentsAll({ limit: -1, offset: 0 }));
    dispatch(getInstrumentsTBD({ limit: -1, offset: 0 }));
    if (!currencyList?.length) dispatch(getCurrency());
    if (!diversificationList?.length) dispatch(getDiversification());
  }, []);

  useEffect(() => {
    if (!instruments?.length) {
      dispatch(getInstrumentsAll({ limit: -1, offset: 0 }));
    } else if (sorter && instruments?.length) {
      let newArray = [];
      sorter === 'byName'
        ? (newArray = instruments.sort((a, b) => textSort(a.name, b.name)))
        : sorter === 'byDate'
          ? (newArray = instruments.sort((a, b) => b.ID - a.ID))
          : (newArray = []);
      setSortedList([...newArray]);
    }
  }, [sorter, instruments]);

  const handleChangeSorter = (e) => {
    setSorter(e.target.value);
  };

  const hendleEdit = (id) => {
    history.push(`/quant/asset/edit-instrument/${id}`, { key: 'edit' });
  };

  const handleDelete = (key) => {
    setModalDeleteVisible(true);
    setDaleteKey(key);
  };
  const onDeleteInstrument = () => {
    dispatch(deleteInstrument(+daleteKey));
    handleCloseDelModal();
  };
  const handleCloseDelModal = () => {
    setDaleteKey(null);
    setModalDeleteVisible(false);
  };

  const getCurrencyById = (id) => {
    return currencyList?.find(el => el.id === id)?.name;
  };

  const getdiversification = (id) => {
    return diversificationList?.find(el => el.id === id)?.short_name;
  };

  const getVehicleType = (value) => {
    const type = [1, 2, 3, 4, 5, 6].includes(value) ? value : 6;
    return t(AssetTypeLabels[type]);
  };

  const columns = [
    {
      title: t('NAME'),
      dataIndex: 'instrument',
      className: 'name',
      sorter: (a, b) => textSort(a.name, b.name),
      width: '17%',
    },
    {
      title: t('ISIN'),
      align: 'center',
      dataIndex: 'ISIN',
      className: 'isin table-header-position-center',
      sorter: (a, b) => textSort(a.ISIN, b.ISIN),
      width: '11%',
    },
    {
      title: t('TICKER'),
      dataIndex: 'ticker',
      render: (value) => value || '- - -',
      sorter: (a, b) => textSort(a.ticker, b.ticker),
      width: '8%',
    },
    {
      title: t('FEE'),
      dataIndex: 'fee',
      className: 'fee table-header-position-center',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (value) => <span>{formatPercentage2(value, 2)} %</span>,
      sorter: (a, b) => a.fee - b.fee,
      width: '8%',
    },
    {
      title: t('SHARE_PRICE'),
      align: 'right',
      dataIndex: 'latest_value',
      className: 'share-price table-header-position-right',
      render: (value) => numberWithSpaces((Math.round(value * 100) / 100).toFixed(2)),
      sorter: (a, b) => a.latest_value - b.latest_value,
      width: '9%',
    },
    {
      title: t('CURRENCY'),
      align: 'center',
      dataIndex: 'currency_id',
      className: 'table-header-position-center',
      render: (value) => getCurrencyById(value),
      sorter: (a, b) => {
        if (getCurrencyById(a.currency_id) < getCurrencyById(b.currency_id)) {
          return 1;
        } else if (getCurrencyById(a.currency_id) > getCurrencyById(b.currency_id)) {
          return -1;
        } else {
          return 0;
        }
      },
      width: '6%',
    },
    {
      title: t('DIVERSIFICATION'),
      align: 'center',
      dataIndex: 'diversification_id',
      className: 'diversification',
      render: (value) => {
        return <span className={`diver-block 
              ${value === 28
                ? 'none'
                : value === 29
                  ? 'medi'
                  : value === 30
                    ? 'hight'
                    : ''}`
              }>
                {getdiversification(value)}
              </span>;
      },
      sorter: (a, b) => {
        if (getdiversification(a.diversification_id) < getdiversification(b.diversification_id)) {
          return 1;
        } else if (getdiversification(a.diversification_id) > getdiversification(b.diversification_id)) {
          return -1;
        } else {
          return 0;
        }
      },
      width: '8%',
    },
    {
      title: t('ASSET_CLASS'),
      dataIndex: 'asset_class',
      className: 'asset-class',
      render: (value, record) => {
        return (
          <>
            <p className="holdings-assetclass-cell-asset">{value}</p>
            <p className="holdings-assetclass-cell-category">
              {record.asset_category}
            </p>
          </>
        );
      },
      sorter: (a, b) => textSort(a.asset_class, b.asset_class),
      width: '14%',
    },
    {
      title: t('INVESTMENT_VEHICLE_TYPE'),
      dataIndex: 'vehicle_type',
      className: 'asset-class',
      render: (value) => getVehicleType(value),
      sorter: (a, b) => textSort(a.asset_class, b.asset_class),
      width: '5%',
    },
    {
      title: t('ACTIONS'),
      align: 'center',
      dataIndex: 'operation',
      width: '8%',
      render: (_, record) => {
        return (
        <div className="cell-button-wrapper">
          <Button
            type='text'
            onClick={() => hendleEdit(record.id)} >
            <Edit />
          </Button>
          <Button
            type='text'
            onClick={() => handleDelete(record.id)}>
            <Delete />
          </Button>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="instrument-wrapper instrument-block-wrap">
        <div className="instrument-inner-wrapper">
          <Header className="headerStyle">
            <Row className="overview-tabs-wrapper">
              <Col className="tabs-tab-wrapper" span={18}>
                <Button
                  type="text"
                  className={`overview-btn ${
                    assetTab === 'list' ? 'ant-btn-active' : ''
                  }`}
                  onClick={() => history.push('/quant/asset/list')} >
                  {t('INSTRUMENTS_TAB')}
                </Button>
                <Button
                  type="text"
                  className={`overview-btn ${assetTab === 'tbd' ? 'ant-btn-active' : ''}`}
                  onClick={() => history.push('/quant/asset/tbd')}>
                  {t('INSTRUMENTS_REQUESTS')}
                </Button>
              </Col>
            </Row>
          </Header>
          <Container widthP={100} mb="28px" padding="0">
            {assetTab === 'tbd'
              ? (
              <InstrumentsTBD />
                )
              : (
              <Instruments
                instruments={sortedList}
                columns={columns}
                TBD={false}
                handleChangeSorter={handleChangeSorter}
                sorter={sorter}
                assetTab={assetTab}
              />
                )}
          </Container>
        </div>
        <MainFooter />
      </div>
      <Modal
        width={650}
        className="instrument-del-modal"
        centered
        open={modalDeleteVisible}
        onCancel={handleCloseDelModal}
        footer={false}
        zIndex={1700}
      >
        <h2 className="del-modal-header">{t('CONFIRM_DELETION')}</h2>
        <p className="del-modal-text">{t('TEXT_DELETION_INSTRUMENT')}</p>
        <p className="del-modal-name">
          {` ${getDeletingInstrument?.name} - - - ${getDeletingInstrument?.isin}`}
        </p>
        <Row
          gutter={16}
          justify={'center'}
          align={'middle'}
          style={{ marginTop: '50px' }}
        >
          <Col>
            <Button className="del-cancel-button" onClick={handleCloseDelModal}>
              {t('CANCEL')}
            </Button>
          </Col>
          <Col>
            <Button className="del-ok-button" onClick={onDeleteInstrument}>
              {t('DELETE')}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AdminInstruments;
