import { put } from '@redux-saga/core/effects';
import { ACTIVATE_ADVISOR, CREATE_ADVISOR, DEACTIVATE_ADVISOR, DELETE_ADVISOR, GET_ADVISORS, GET_ADVISORS_AWAIT_APPROVE, GET_USERS_FOR_ADVISOR, REINVITE_ADVISOR, UPDATE_ADVISOR } from './advisorActionTypes';
import { AdvisorService } from '../../../services/AdvisorService';

export function * getAdvisors (action) {
  try {
    yield put({ type: GET_ADVISORS.REQUEST });
    const response = yield AdvisorService.getAll(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_ADVISORS.SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_ADVISORS.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: GET_ADVISORS.ERROR, payload: e.response.data.message });
  }
}

export function * getUsersForAdvisor () {
  try {
    yield put({ type: GET_USERS_FOR_ADVISOR.REQUEST });
    const response = yield AdvisorService.getUsersForAdvisor();
    if (response.status === 200) {
      yield put({ type: GET_USERS_FOR_ADVISOR.SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_USERS_FOR_ADVISOR.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: GET_USERS_FOR_ADVISOR.ERROR, payload: e.response.data.message });
  }
}

export function * getAdvisorsAwaitApprove (action) {
  try {
    yield put({ type: GET_ADVISORS_AWAIT_APPROVE.REQUEST });
    const response = yield AdvisorService.getAwaitApprove(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_ADVISORS_AWAIT_APPROVE.SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_ADVISORS_AWAIT_APPROVE.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: GET_ADVISORS_AWAIT_APPROVE.ERROR, payload: e.response.data.message });
  }
}

export function * activateAdvisor (action) {
  try {
    const { id, history } = action.payload;
    yield put({ type: ACTIVATE_ADVISOR.REQUEST });
    const response = yield AdvisorService.activate(id);
    if (response.status === 200) {
      yield put({ type: ACTIVATE_ADVISOR.SUCCESS, payload: +id });
      if (history) {
        history.push('/advisors/advisors-list');
      }
      yield put({ type: GET_ADVISORS.ACTION, payload: { limit: -1, offset: 0 } });
    } else {
      yield put({ type: ACTIVATE_ADVISOR.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: ACTIVATE_ADVISOR.ERROR, payload: e.response?.data?.message });
  }
}

export function * deactivateAdvisor (action) {
  try {
    yield put({ type: DEACTIVATE_ADVISOR.REQUEST });
    const response = yield AdvisorService.deactivate(action.payload);
    if (response.status === 200) {
      yield put({ type: DEACTIVATE_ADVISOR.SUCCESS });
      yield put({ type: GET_ADVISORS.ACTION, payload: { limit: -1, offset: 0 } });
    } else {
      yield put({ type: ACTIVATE_ADVISOR.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: ACTIVATE_ADVISOR.ERROR, payload: e.response.data.message });
  }
}

export function * createAdvisor (action) {
  const { data, callback, history, path } = action.payload;
  try {
    yield put({ type: CREATE_ADVISOR.REQUEST });
    const response = yield AdvisorService.create(data);
    if (response.status === 200) {
      yield put({ type: CREATE_ADVISOR.SUCCESS });
      callback && callback();
      if (path) {
        history.replace(path);
      }
    } else {
      yield put({ type: CREATE_ADVISOR.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: CREATE_ADVISOR.ERROR, payload: e.response?.data?.message });
  }
}

export function * updateAdvisor (action) {
  const { history, path } = action.payload;
  try {
    yield put({ type: UPDATE_ADVISOR.REQUEST });
    const response = yield AdvisorService.update(action.payload);
    if (response.status === 200) {
      yield put({ type: UPDATE_ADVISOR.SUCCESS });
      yield put({ type: GET_ADVISORS.ACTION, payload: { limit: -1, offset: 0 } });
      if (path) {
        history.push(path);
      }
    } else {
      yield put({ type: CREATE_ADVISOR.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: CREATE_ADVISOR.ERROR, payload: e.response?.data?.message });
  }
}

export function * reinviteAdvisor (action) {
  try {
    yield put({ type: REINVITE_ADVISOR.REQUEST });
    const response = yield AdvisorService.reinvite(action.payload);
    if (response.status === 200) {
      yield put({ type: REINVITE_ADVISOR.SUCCESS });
      yield put({ type: GET_ADVISORS.ACTION, payload: { limit: -1, offset: 0 } });
    } else {
      yield put({ type: REINVITE_ADVISOR.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: REINVITE_ADVISOR.ERROR, payload: e.response?.data?.message });
  }
}

export function * deleteAdvisor (action) {
  try {
    yield put({ type: DELETE_ADVISOR.REQUEST });
    const response = yield AdvisorService.delete(action.payload);
    if (response.status === 200) {
      yield put({ type: DELETE_ADVISOR.SUCCESS });
      yield put({ type: GET_ADVISORS.ACTION, payload: { limit: -1, offset: 0 } });
    } else {
      yield put({ type: DELETE_ADVISOR.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: DELETE_ADVISOR.ERROR, payload: e.response?.data?.message });
  }
}
