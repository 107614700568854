/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Up } from '../../assets/green-up.svg';
import { ReactComponent as Delete } from '../../assets/delete-icon.svg';
import { ReactComponent as Export } from '../../assets/export-icon.svg';
import { ReactComponent as Persent } from '../../assets/persent-icon.svg';

import { Button, Col, Modal, Row } from 'antd';

import { fractionalWithSpaces } from '../../helpers/formatFractional';
import dayjs from 'dayjs';
import StorageService from '../../services/StorageService';
import API from '../../utils/API';
import { API_URLS } from '../../constants/api_urls';

import { deletePortfolio, exportPortfolio } from '../../data/store/portfolio/portfolioActions';
import './style.scss';

const PortfolioDetails = ({ userId, portfolioId }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, portfolioById } = useSelector((state) => state.portfolioReducer);
  const [creator, setCreator] = useState();
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [modalExportVisible, setModalExportVisible] = useState(false);
  const {
    name, total_value, created_at, created_by, updated_at,
    portfolio_content, unlisted_content, portfolio_cashes, status, is_locked
  } = portfolioById;

  useEffect(() => {
    async function fetchData () {
      try {
        const token = StorageService.getJWTToken();
        const response = await API.get(`${API_URLS.ADVISOR_V3}/${created_by}`, {
          headers: { Authorization: 'Bearer ' + token },
        });
        setCreator(`${response?.data?.first_name} ${response?.data?.last_name}`);
      } catch (error) {
        console.log('Error', error?.response);
      }
    }
    if (created_by) {
      fetchData();
    }
  }, [created_by]);

  const handleCloseDelModal = () => {
    setModalDeleteVisible(false);
  };

  const handleDeleteModal = () => {
    setModalDeleteVisible(true);
  };

  const submitDelete = () => {
    dispatch(
      deletePortfolio({
        history,
        portfolioId,
        clientId: +userId,
      })
    );
    setModalDeleteVisible(false);
  };

  const handleExportModal = () => {
    setModalExportVisible(true);
  };

  const onExport = () => {
    dispatch(exportPortfolio(portfolioId));
    setModalExportVisible(false);
  };

  return (
    <>
      {!isLoading &&
      <>
      <Row className="portfolio-details-wrapper">
        <Col span={18}>
          <h2 className="portfolio-details-title">{t('PORTFOLIO_DETAILS')}</h2>
        </Col>
        <Col span={6} className='button-wrap'>
          <Button
            type='text'
            disabled={is_locked || status === 'ACTIVE_DEPRECATED'}
            icon={<Delete/>}
            iconPosition='end'
            className="portfolio-delete-btn"
            onClick={handleDeleteModal}
          >
            {t('DELETE_PORTFOLIO')}
          </Button>
          <Button
            type='text'
            icon={<Export/>}
            iconPosition='end'
            className="portfolio-export-btn"
            onClick={handleExportModal}
          >
            {t('EXPORT_PORT')}
          </Button>
        </Col>
      </Row>
      <div className='portfolio-details-data-wrapper'>
        <div className="portfolio-details-data-left">
          <div className="portfolio-details-data">
            <h2 className='portfolio-details-name'>{name}</h2>
            <p className='portfolio-details-amount'>{fractionalWithSpaces(total_value)} Sek</p>
          </div>
        </div>
        <div className="portfolio-details-data-right">
          {portfolio_content?.length || unlisted_content?.length || portfolio_cashes?.length
            ? <div className="portfolio-details-data">
            <div className="details-data-col fbasis-22">
              <h3 className='details-create'>{t('CREATED_ON')}</h3>
              <p className='details-date'>{dayjs(created_at).format('DD MMM YYYY').toUpperCase()}</p>
              <p className='details-createdBy'>{t('BY')}{` ${creator}`}</p>
            </div>
            <div className="details-data-col fbasis-22">
              <h3 className='details-create'>{t('LAST_OPTIMIZED_ON')}</h3>
              <p className='details-date'>{dayjs(updated_at).format('DD MMM YYYY').toUpperCase()}</p>
              <p className='details-createdBy'>{t('BY')}{` ${creator}`}</p>
            </div>
            <div className="details-data-col fbasis-18">
              <h3 className='details-create'>{t('PERFORMANCE')}</h3>
              <div className='details-date'>
                <p className={'rebalance-color-data green'}>
                  <Up/>
                  0.83%
                </p>
              </div>
              <p className='details-createdBy'>{t('THAN_LAST_MONTH')}</p>
            </div>
            <div className="details-data-col fbasis-18">
              <h3 className='details-create'>{' '}</h3>
              <div className='details-date'>
                <p className={'rebalance-color-data green'}>
                  <Up/>
                  2.79%
                </p>
              </div>
              <p className='details-createdBy'>{t('THAN_LAST_3_MONTHS')}</p>
            </div>
            <div className="details-data-col fbasis-18">
              <h3 className='details-create'>{' '}</h3>
              <div className='details-date'>
                <p className={'rebalance-color-data green'}>
                  <Up/>
                  6.71%
                </p>
              </div>
              <p className='details-createdBy'>{t('THAN_LAST_YEAR')}</p>
            </div>
              </div>
            : (
              <div className="portfolio-details-data">
              <div style={{ width: '100%' }}>

                <Row justify={'center'} style={{ width: '100%' }}><Persent /></Row>
                <Row justify={'center'} style={{ width: '100%' }}>
                  {t('DETAIL_TEXT')}
                </Row>
              </div>
              </div>
              )
          }
        </div>
      </div>
      <Modal
        width={650}
        className="modal-content-paper"
        open={modalDeleteVisible}
        onCancel={handleCloseDelModal}
        footer={null}
        zIndex={1600}>
        <Row justify="center">
          <Col className="modal-title">{t('MODAL_DELETE_TITLE')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col>
            <p className="modal-advisor-text">{t('MODAL_DELETE_PORTFOLIO')}</p>
            <p className="modal-advisor-name">{name}</p>
          </Col>
        </Row>
        <Row className="modal-advisor-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={handleCloseDelModal}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn delete-btn" onClick={submitDelete}>
            {t('DELETE')}
          </Button>
        </Row>
      </Modal>
      <Modal
          width={650}
          className="modal-content-paper"
          open={modalExportVisible}
          onCancel={() => setModalExportVisible(false)}
          footer={null}
          zIndex={1600}>
          <Row justify="center">
            <Col className="modal-title">
            {t('EXPORT_PORTFOLIO')}
            </Col>
          </Row>
          <Row justify="center" className="modal-text-wrapper">
            <Col>
              <p className="modal-advisor-text">
              {t('EXPORT_PORTFOLIO_PROMT')}
              </p>
              <p className="modal-advisor-name" style={{ fontWeight: '600' }}>{portfolioById?.name} {` (code: ${portfolioById?.portfolio_code})`} </p>
            </Col>
          </Row>
          <Row className="modal-advisor-btn-wrapper">
            <Button
              className="modal-action-btn cancel-btn"
              onClick={() => setModalExportVisible(false)}
            >
              {t('CANCEL')}
            </Button>
            <Button
              className='modal-action-btn save-btn'
              onClick={onExport}>
              {t('EXPORT')}
            </Button>
          </Row>
      </Modal>
      </>
      }
    </>
  );
};

export default PortfolioDetails;

PortfolioDetails.propTypes = {
  userId: PropTypes.string,
  portfolioId: PropTypes.string,
};
