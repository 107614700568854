import React from 'react';

import PropTypes from 'prop-types';

import { Button, Col, Modal, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { deleteAccount } from '../../data/store/account/accountActions';

const ModalDeleteAccount = ({name, t, history, deleteAccountModal, closeDeleteAccountModal}) => {
  const dispatch = useDispatch();

  const afterDel = () => {
    closeDeleteAccountModal();
    history.push('/accounts');
  };

  const submitDelete = () => {
    console.log('deleteAccountModal', deleteAccountModal);
    dispatch(deleteAccount(deleteAccountModal, afterDel));
    
  };
  
  return (
    <Modal
      width={650}
      className="modal-content-paper"
      open={deleteAccountModal}
      onCancel={closeDeleteAccountModal}
      footer={null}
      zIndex={1600}
    >
      <Row justify="center">
        <Col className="modal-title">{t('MODAL_DELETE_TITLE')}</Col>
      </Row>
      <Row justify="center" className="modal-text-wrapper">
        <Col>
          <p className="modal-advisor-text">{t('MODAL_DELETE_ACCOUNT')}</p>
          <p className="modal-advisor-name">{name}</p>
        </Col>
      </Row>
      <Row className="modal-advisor-btn-wrapper">
        <Button
          className="modal-action-btn cancel-btn"
          onClick={closeDeleteAccountModal}
        >
          {t('CANCEL')}
        </Button>
        <Button className="modal-action-btn delete-btn" onClick={submitDelete}>
          {t('DELETE')}
        </Button>
      </Row>
    </Modal>
  );
};

export default ModalDeleteAccount;

ModalDeleteAccount.propTypes = {
  name: PropTypes.string,
  t: PropTypes.func,
  history: PropTypes.object,
  deleteAccountModal: PropTypes.string,
  closeDeleteAccountModal: PropTypes.func,
};
