import API, { axios } from '../utils/API';
import { API_URLS } from '../constants/api_urls';
import StorageService from './StorageService';
import SessionService from './SessionService';

export default class UserService {
  static userProfileUpdate = async ({ id, data }) => {
    const token = StorageService.getJWTToken() || SessionService.getJWTToken();
    return await API.put(`${API_URLS.UPDATE_ADVISER_V3}/${id}`, data, {
      headers: { Authorization: 'Bearer ' + token },
    });
  };

  static restorePassword = async () => {
    return Promise.resolve('success');
  };

  static resetPassword = async () => {
    return Promise.resolve('success');
  };

  static login = async (data) => {
    return API.post(API_URLS.LOGIN, data);
  };

  static registration = async (data) => {
    return await API.post(API_URLS.REGISTER_V3, data);
  };

  static forgotPasswordRequest = async (email) => {
    return await API.post(API_URLS.FORGOT_PASSWORD_REQUEST, email);
  };

  static forgotPasswordReset = async (data) => {
    return await API.put(API_URLS.FORGOT_PASSWORD_RESET, data);
  };

  static verifyEmail = async ({ code }) => {
    return await API.put(API_URLS.VERIFY_EMAIL + `?code=${code}`);
  };

  static confirmEmail = async (hash) => {
    return await API.post(`${API_URLS.CONFIRM_EMAIL}/${hash}`);
  };

  static emailChange = async (data) => {
    return await API.put(API_URLS.CHANGE_EMAIL, data);
  };

  static emailResend = async (data) => {
    return await API.put(API_URLS.RESEND_EMAIL, data);
  };

  static updateEmail = async ({ id, email }) => {
    return await axios.put(`${API_URLS.UPDATE_EMAIL}/${id}/email`, email);
  };

  static updateUserPhones = async ({ id, phones }) => {
    const token = StorageService.getJWTToken() || SessionService.getJWTToken();
    return await axios.patch(`${API_URLS.ADVISOR_V3}/${id}/phone`, phones,
    { headers: { Authorization: 'Bearer ' + token } });
  };

  static updateUserRole = async ({ id, role }) => {
    const token = StorageService.getJWTToken() || SessionService.getJWTToken();
    return await API.put(`${API_URLS.ADVISORS_V3}/${id}/role`, {role},
    { headers: { Authorization: 'Bearer ' + token } });
  };

  static updateUserData = async ({ id, data }) => {
    const token = StorageService.getJWTToken() || SessionService.getJWTToken();
    return await API.put(`${API_URLS.ADVISORS_V3}/${id}`, data,
    { headers: { Authorization: 'Bearer ' + token } });
  };

  static updatePassword = async ({ id, password }) => {
    const token = StorageService.getJWTToken() || SessionService.getJWTToken();
    return await API.put(
      `${API_URLS.UPDATE_PASSWORD}/${id}/password`,
      password,
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  static getAdvisorById = async (id) => {
    const token = StorageService.getJWTToken() || SessionService.getJWTToken();
    return await API.get(`${API_URLS.ADVISOR_V3}/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    });
  };

  static checkToken = async () => {
    const token = StorageService.getJWTToken() || SessionService.getJWTToken();
    return await API.get(API_URLS.CHECK_TOKEN, {
      headers: { Authorization: 'Bearer ' + token },
    });
  };

  static getQRCodeBankID = async () => {
    // const token = StorageService.getJWTToken();
    return await API.post(API_URLS.BANKID_AUTH);
  };

  static getQRCodeSignBankID = async (number) => {
    const token = StorageService.getJWTToken() || SessionService.getJWTToken();
    return await API.post(
      API_URLS.BANKID_SIGN,
      { personal_number: number },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  static getQRCodeAttachBankID = async (number) => {
    const token = StorageService.getJWTToken() || SessionService.getJWTToken();
    return await API.post(
      API_URLS.BANKID_SIGN_ATTACH,
      { personal_number: number?.toString() },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  static collectBankID = async (ref) => {
    // const token = StorageService.getJWTToken();
    return await API.get(`${API_URLS.BANKID_COLLECT}?order_ref=${ref}`);
  };

  static getTwoFactorSecret = async () => {
    const token = StorageService.getJWTToken() || SessionService.getJWTToken();
    return await API.post(
      API_URLS.GET_SECRET,
      {},
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  static getTwoFactorSecretCheck = async (payload) => {
    const token = StorageService.getJWTToken() || SessionService.getJWTToken();
    return await API.post(API_URLS.SECRET_BIND, payload, {
      headers: { Authorization: 'Bearer ' + token },
    });
  };

  static clearIdentification = async (id) => {
    const token = StorageService.getJWTToken() || SessionService.getJWTToken();
    return await API.delete(`${API_URLS.ADVISOR_V3}/${id}/identification`, {
      headers: { Authorization: 'Bearer ' + token },
    });
  };
}
