export const DashboardEN = {
  CUSTOMER_INFORMATION: 'Customer information',
  COMPANY_INFORMATION: 'Company information',
  CLIENT_DASHBOARD: 'Client\'s dashboard',
  REMOVE: 'Remove',
  ECONOMIC_SITUATION: 'Economic situation',
  RISK_PROFILE: 'Risk profile',
  CUSTOMER_KNOWLEDGE: 'Customer knowledge',
  COMPANY_KNOWLEDGE: 'Company knowledge',
  ASSIGNMENT_AGREEMENT: 'Assignment agreement',
  PURPOSE_MATTERS: 'Purpose',
  // PURPOSE_MATTERS: 'Purpose & Matters of the Heart',
  ENTER: 'Enter',
  CORRECT: 'correct',
  FINANCIAL_PLANNING: 'Financial planning',
  LEGAL_ANALYSIS: 'Legal needs analysis',
  EXPERIENCE_BACKGROUND: 'Experience background',
  PURCHASE_INFORMATION: 'Insurance account',
  SIGN_AGREEMENT: 'Sign agreement',
  SOCIAL_SECURITY_NUMBER: 'Social security number',
  ORGANIZATION_NUMBER: 'Organization number',
  PRIVATPERSON: 'Private person',
  CORPORATE_CLIENT: 'Corporate client',
  ENTER_FIRST_NAME: 'Enter first name',
  ANGE_EFTERNAMN: 'Enter last name',
  CONTACT_DETAILS: 'Contact details',
  MAILADRESS: 'Email address',
  ENTER_EMAIL_ADDRESS: 'Enter email address',
  MOBILE_NUMBER: 'Mobile number',
  ADD_MORE_PHONE_NUMBERS: 'Add phone number',
  ENTER_PHONE_NUMBER: 'Enter phone number',
  ADDRESS: 'Address',
  POSTAL_ADDRESS: 'Postal address',
  ENTER_POSTAL_ADDRESS: 'Enter postal address',
  ENTER_CO_ADDRESS: 'Enter C/O address',
  ZIP_CODE: 'ZIP code',
  ENTER_ZIP_CODE: 'Enter zip code',
  CITY: 'City',
  COUNTRY: 'Country (residency)',
  ENTER_CITY: 'Enter city',
  COUNTRY_COMPANY: 'Country',
  LEI_CODE: 'LEI-code',
  ENTER_COUNTRY: 'Enter country',
  MARITAL_STATUS_AND_EMPLOYMENT: 'Marital status and employment',
  MARITAL_STATUS: 'Marital status',
  SELECT_OPTION: 'Select option',
  CHILDREN_OR_OTHERS_DEPENDENT: 'Children or others dependent on support',
  ACCOUNT_TEXT1: 'The following accounts will be opened. The share & fund account is our basic account and',
  ACCOUNT_TEXT2: 'opens automatically when you become a customer with us. The investment savings account (ISK) is it',
  ACCOUNT_TEXT3: 'account that is most popular among our customers. If you don\'t want an ISK, tick',
  ACCOUNT_TEXT4: 'man from the box below.',
  STOCK_FUND_ACCOUNT: 'Stock & fund account',
  INVESTMENT_SAVINGS_ACCOUNT: 'Investment savings account',
  ENDOWMENT_INSURANCE: 'Endowment insurance',
  GO_BACK: 'Go back',
  MOVE_ON: 'Move on',
  DETAILS: 'Details',
  MY_DETAILS: 'My details',
  COMPANY_DETAILS: 'Company details',
  MARRIED: 'Married or cohabitant',
  NO_MARRIED: 'Unmarried',
  WIDOW_OR_WIDOWER: 'Widow or widower',

  PENSIONER: 'Pensioner',
  OTHER: 'Other',
  PURPOSE_AND_HEARTA: 'Purpose and heart issues with the investments',
  WHAT_IS_THE_PURPOSE: 'What is the purpose of your investments?',
  DO_YOU_HAVE_ANY_MATTERS: 'Any “matters close to your heart” that you would like us to consider when choosing investments?',

  INVESTMENT_TOWARDS_TARGET: 'Investment towards target',
  INVESTMENT: 'Investment',
  WHICH_OF_BELOW_STATEMENTS: 'Which of below statements best describes your view of investments and sustainability issues?',
  MY_FOCUS_IN_INVESTMENTS: 'My focus in investments is risk and return.',
  I_WANT_TO_INTEGRATE_SUSTAINABILITY: 'I want to integrate sustainability aspects where possible without lowering the requirements for risk and return.',
  SUSTAINABILITY_IS_A_VERY_IMPORTANT: 'Sustainability is a very important issue in my portfolio and I can imagine lowering the requirements for risk and return to exclude certain industries.',
  ARE_THERE_ANY_OTHER_ASPECTS: 'Are there any other aspects of your portfolio Citroneer should be aware of?',
  INCOME_AND_EXPENSES: 'Income and expenses',
  AFTER_TAX: 'After tax',
  INCOME_FROM_EMPLOYMENT: 'From employment',
  OTHER_INCOME: 'Other income',
  EXPENDITURE: 'Expenses',
  EXCESS: 'Surplus',
  COMMENT: 'Comment',
  FINANCIAL_ASSETS: 'Financial assets',
  FINANCIAL_INVESTMENTS: 'Financial investments',
  CASH_AND_CASH_EQUIVALENTS: 'Cash and cash equivalents',
  PENSION_SAVINGS_AND_SIMILAR: 'Pension savings and similar',

  HOME: 'Home',
  OTHER_REAL_ASSETS: 'Other real assets',
  DEBTS: 'Debts',
  MORTGAGES: 'Mortgages',
  OTHER_DEBTS: 'Other debts',
  INVESTABLE_CAPITAL: 'Investable capital',
  PARTS_THAT_SHOULD_NOT_BE_COVERED: 'Are there parts that should not be covered by the advice?',
  CAPITAL_NOT_BE_COVERED: 'Capital that will not be covered by the advice',
  INVESTIBLE_CAPITAL: 'Investible capital',
  INVESTIBLE_CAPITAL_ADV: 'Investable capital (included in advice)',
  SAVING: 'Savings',
  IS_THERE_NEED_FOR_ABILITY_LIQUIDATE: 'Is there a need for the ability to liquidate holdings at short notice?',
  YES: 'Yes',
  NO: 'No',
  HOW_FUTURE_GENERATIONS: 'Are there any ideas of how future generations should take part in the portfolio return that Citroneer should be aware of?',

  YEAR: 'year',
  REGULAR_DEPOSITS: 'Regular deposits',
  ENTER_REGULAR_DEPOSITS: 'Enter regular deposits',
  BUFFER_SAVINGS: 'Buffer savings',
  ENTER_YOUR_DESIRED_BUFFER_SAVING: 'Enter your desired buffer saving',
  GOAL_SAVING: 'Goal saving',
  ENTER_NAME: 'Enter name',
  YEAR_OF_PURCHASE: 'Year of purchase',
  ENTER_YEAR_OF_PURCHASE: 'Enter year of purchase',
  AMOUNT: 'Amount',
  ENTER_AMOUNT: 'Enter amount',
  ADD_SAVING: 'Add more target saves',
  ADD_ANOTHER_COUNTRY: 'Add another country',
  YEARS_LEFT_UNTIL_RETIREMENT: 'Years left until retirement',
  ENTER_YEARS_LEFT_UNTIL_RETIREMENT: 'Enter years left until retirement',
  REGULAR_WITHDRAWALS: 'Regular withdrawals',
  ENTER_REGULAR_WITHDRAWALS: 'Enter regular withdrawals',
  REQUIREMENT_FOR_RESIDUAL_CAPITAL: 'Requirement for residual capital',
  ENTER_REQUIREMENTS_FOR_RESIDUAL: 'Enter requirements for residual capital after the end of the time period',
  RISK_TOLERANCE: 'Risk tolerance',
  HOW_DO_YOU_VIEW_RISK: 'How do you view risk?',
  WHAT_IS_THE_ANNUAL_EXPECTED: 'What is the annual expected investment return?',
  IN_LINE_WITH_THE_BANK: 'In line with the bank savnings rate',
  SOMEWHAT_ABOVE_THE_BANK: 'Somewhat above the bank savings rate',
  SUBSTANTIALLY_ABOVE_THE_BANK: 'Substantially above the bank savings rate',
  I_HAVE_A_DEFINED_TARGET: 'I have a defined target return number',
  ENTER_THE_RETURN_TARGET: 'Enter the return target (%)',
  WHICH_OF_THE_BELOW_BEST_DESCRIBES: 'Which of the below best describes your investment philosophy?',
  FOCUS_TO_CONTROLL_RISK_AND_LOSSES: 'Focus to controll risk and losses and I accept a rather low return on the investment',
  ACCEPT_A_SOMEWHAT_HIGHER_RISK: 'Accept a somewhat higher risk to gain a higher return.',
  FOCUS_IS_ON_HIGH_RETURN: 'Focus is on high return so I accept higher fluctuations in my portfolio.',
  HOW_IS_YOUR_VIEW_OF_INVESTMENTS: 'How is your view of investments affected when the media is flooded with negative market news and experts offer investment tips?',
  NEWS_DOES_NOT_EFFECT: 'News does not effect my view and no worries regarding the porfolio.',
  WHEN_I_RECIEVE_NEGATIVE_MARKET: 'When I recieve negative market news I get more engaged in my investments.',
  WHEN_I_RECEIVE_NEGATIVE_NEWS: 'When I receive negative news I follow the experts and change my portolio accordingly.',
  I_GET_MORE_INVOLVED_IN_MY_INVESTMENTS: 'I get more involved in my investments but act without any feelings, I\'m cool.',
  ASSUME_THAT_YOUR_PORTFOLIO_TODAY: 'Assume that your portfolio today is worth around SEK 50 million. What would be your reaction should it be valued at SEK 45 million after twelve months?',
  A_NEED_TO_LOWER_MY_RISK: 'A need to lower my risk in the portfolio and for example sell some of my stocks.',
  THE_PORTFOLIO_IS_LEFT_INTACT: 'The portfolio is left intact, but reduce the dividend from the portfolio.',
  CONTACT_THE_ADVISOR_TO_DISCUSS: 'Contact the advisor to discuss the portfolio.',
  NONE_ITS_NATURAL_WITH_BIG_FLUCTUATIONS: 'None, it\'s natural with big fluctuations in the markets.',
  AN_OPPORTUNITY_TO_TAKE_ADVANTAGE: 'An opportunity to take advantage of and increase the risk, and, for example, buy more stocks.',
  HOW_WOULD_YOUR_REACTION_BE_DIFFERENT: 'How would your reaction be different if it was instead valued at SEK 40 million?',
  LARGE_AND_SMALL_MOVEMENTS: 'None, large and small movements are a natural part of investing.',
  I_CAN_UNDERSTAND_SMALL_FLUCTUATIONS: 'I can understand small fluctuations, but large drops clearly make me stressed.',
  WHAT_WOULD_BE_YOUR_REACTION: 'What would be your reaction if the Stockholm Stock Exchange is up by 20 percent while your portfolio is only up by 5 percent?',
  FRUSTRATION_OVER_THE_MISSED: 'Frustration over the missed opportunity and of slipping behind the stock market.',
  NOTHING_REALLY_IM_AWARE_THAT: 'Nothing really, I\'m aware that the Swedish stock market has higer risk than my portfolio.',
  I_SPEAK_WITH_MY_ADVISOR_TO_UNDERSTAND: 'I speak with my advisor to understand why my portfolio is performing worse.',
  NOTHING_THE_PORTFOLIO_IS_BALANCED: 'Nothing, the portfolio is balanced and will not keep up with individual market movements.',
  DO_YOU_COMPARE_YOUR_PORTFOLIO: 'Do you compare your portfolio with something/how do you evaluate your investments?',
  COMPARE_MY_RESULT_WITH_THE_SWEDISH: 'I compare my result with the Swedish stock market.',
  COMPARE_MY_RESULT_WIHT_A_GLOBAL: 'I compare my result wiht a global stock index.',
  I_HAVE_A_SPECIFIC_BENCHMARK: 'I have a specific benchmark.',
  I_COMPARE_MY_PORTFOLIO_WITH: 'I compare my portfolio with a fund/fund manager.',
  TO_WHICH_EXTENT_DO_FINANCIAL: 'To which extent do financial news affect your view of investments?',
  WHEN_I_RECIEVE_NEGATIVE_NEWS: 'When I receive negative news I follow the experts and change my portolio accordingly.',
  I_GET_MORE_INVOLVED: 'I get more involved in my investments but act without any feelings, I\'m cool.',
  COMMENT_RISK_AND_RETURN: 'Comment: Risk and return expectations',
  RISK: 'Risk',
  RISK_PARAGRAF1: 'When you choose the risk class for your savings, it is important to remember that you also choose the average return you can expect. For diversified savings over longer periods of time, the relationship normally applies that lower risk leads to lower returns and that higher returns require higher risk. Note that the correlation is weak for individual securities and/or shorter time periods. If your investment horizon is short, you should therefore choose lower risk than if your investment horizon is long.',
  RISK_PARAGRAF2: 'On the European market, risk and return are described using a seven-point scale called the Summary Risk Indicator (SRI). Simply stated, it is the expected variation of the return that determines the value of the SRI. The table below describes risk and return for a single year. The other numbers in the table aim to relate the seven-point scale to concrete values for return and risk.',
  RISK_CLASS: 'Risk class',
  EXAMPLE_OF_PRODUCT: 'Example of product',
  VOLATILITY_ANNUAL: 'Volatility (annual)',
  RETURN_ANNUAL: 'Return (annual)',
  RISK_OF_AN_ANNUAL_RETURN_LOWER: '10% risk of an annual return lower than',
  VERY_LOW_RISK: 'Very low risk',
  GOVERNMENT_BOND: 'Government bond',
  LOW_RISK: 'Low risk',
  CORPORATE_BOND: 'Corporate Bond (Investment Grade)',
  MEDIUM_LOW_RISK: 'Medium-low risk',
  CORPORATE_BOND_HIGH_YIELD: 'Corporate bond (High Yield)',
  MEDIUM_RISK: 'Medium risk',
  BROAD_STOCK_PORTFOLIO_INDEX: 'Broad stock portfolio (index)',
  MEDIUM_HIGH_RISK: 'Medium high risk',
  STOCK_SMALL_COMPANIES: 'Stock (small companies)',
  HIGH_RISK: 'High risk',
  STRUCTURED_PRODUCT: 'Structured product',
  VERY_HIGH_RISK: 'Very high risk',
  WARRANT: 'Warrant',
  READ_MORE: 'Read more',
  RISK_CLASSIFICATION_PROPOSAL: 'Citroneer\'s risk classification proposal',
  RISK_PARAGRAF3: 'Based on your previous answers regarding your risk tolerance, Citroneer judges that risk class 4 (Medium risk) is an appropriate risk class for your purposes.',
  LEGAL_NEEDS_PLANNING: 'Legal needs planning',
  STRUCTURE_FOR_INVESTMENTS: 'Structure for investments, future plan risk',
  WHAT_INVESTMENTS_YOU_CURRENTLY: 'What investments you currently have and what is the structure for these (privately owned, capital insurance, ISK or corporate)?',
  WHAT_OTHER_ASSETS_DO_YOU_CURRENTLY: 'What other assets do you currently have (i.e. real estate in Sweden or abroad, or other types of investments of more long-term nature)?',
  HAVE_YOU_ASSETS_THAT: 'Have you assets that are jointly owned with others or investments that are covered by shareholder agreements, wills, appointments as private property or similar?',
  WHAT_ARE_THE_PLANS: 'What are the plans for the future - is the intention to create some form of management structure / company (if one does not already exist)?',
  OTHER_ISSUES_OF_A_LEGAL: 'Other issues of a legal nature that you want to discuss or flag up before the discussion?',
  COMMENT_LEGAL: 'Comment: Legal needs analysis',
  WHAT_YOUR_COUNTRY_CITIZENSHIP: 'What is your country of citizenship?',
  COUNTRY_CITIZENSHIP: 'Country of citizenship',
  COUNTRY_TAX: 'Country of tax residence',
  OWNERSHIP: 'Ownership',
  Sweden: 'Sweden',
  WHAT_YOUR_COUNTRY_TAX_RESIDENCE: 'What is your country of tax residence?',

  HAVE_YOU_ANY_RELATIONSHIP_WITH: 'Have you any relationship with the United States?',
  DO_YOU_REGULARLY_MAKE_TRANSACTIONS: 'Do you regularly make transactions abroad?',
  WHAT_PURPOSE: 'What purpose?',
  PURPOSE_WITH_INVESTMENT: 'Purpose with investment',
  WHAT_PURPOSE_YOUR_BUSINESS: 'What is the purpose of your business with Citroneer?',
  BEHALF_ARE_YOU_INVESTING: 'On whose behalf are you investing?',
  FOR_MY_OWN_PART: 'For my own part',
  FOR_SOMEONE_ELSE_BY_PROXY: 'For someone else by proxy',
  WHAT_YOUR_MAIN_OCCUPATION: 'What is your main occupation?',
  APPROXIMATE_MONTHLY_INCOME: 'What is your approximate monthly income?',
  OVER_100000: 'Over 100 000 kr',
  CAPITAL_TRANSFER: 'Capital transfer',
  PERSONAL_MONEY_THAT_WILL_TRANSFERED: 'Is it your personal money that will be transfered?',
  WHERE_DOES_THE_MONEY: 'Where does the money and/or securities you deposit in Citroneer come from?',
  OWN_COMPANY: 'Own company (salary or dividend)',
  INHERITAGE: 'Inheritage/Gift',
  HOUSING_PROPERTY: 'Housing or property sale',
  CAPITAL_GAINS_COMPANY: 'Capital gains from company sale',
  OTHER_SALE: 'Other sale (for example, car or boat sale)',
  CAPITAL_GAINS_SECURITIES: 'Capital gains from securities trading',
  LOTTERY_GAMBLING: 'Lottery/Gambling',
  INSURANCE_PAYMENT: 'Insurance payment',
  ALLOWANCE: 'Allowance/Unemployment benefit',
  FROM_WHICH_BANK_INSURANCE: 'From which bank/insurance company will you transfer the money?',
  SWEDISH_BANK: 'Swedish bank',
  SWEDISH_INSURANCE_COMPANY: 'Swedish insurance company',
  FORREIGN_BANK: 'Forreign bank',
  FORREIGN_INSURANCE_COMPANY: 'Forreign insurance company',
  ESTIMATED_VALUE_WILL_YOU_TRANSFER: 'What estimated value will you transfer when opening the account with Citroneer?',
  OVER_100000000: 'Over 100 000 000 kr',
  DEPOSITS_WITHDRAWALS_TRANSACTIONS: 'Deposits, withdrawals and transactions',
  WHAT_VALUE_YOUR_DEPOSITS_AMOUT: 'What value will your deposits amount to on average per year?',
  OVER_10000000: 'Over 10 000 000 kr',
  OFTEN_WILL_DEPOSITS: 'How often will deposits be made per year?',
  TIMES_0_10: '0 - 10 times',
  TIMES_10_50: '10 - 50 times',
  TIMES_50_100: '50 - 100 times',
  MORE_THAN_100_TIMES: 'More than 100 times',
  OFTEN_WILL_WITHDRAWALS: 'How often will withdrawals be made per year?',
  HOW_LARGE_INDIVIDUAL_TRANSACTIONS: 'How large individual transactions in financial instruments do you normally make?',
  POLITICALLY_EXPOSED_POSITION: 'Person in a politically exposed position',
  KNOWN_EMPLOYEE_SUCH_PERSON: 'Are you or have you been a person in a politically exposed position or a family member or a known employee of such a person?',

  DO_YOU_HAVE_EDUCATION: 'Do you have education or have you worked professionally with financial investments  and do you understand how the financial markets work?',
  HOW_MANY_YEARS_INVESTING: 'How many years have you been investing in the stockmarket or other financial instruments?',
  ENTER_THE_NUMBER_YEARS: 'Enter the number of years you have traded securities',
  SPECIFIC_KNOWLEDGE_DIFFERENT: 'Specific knowledge of different asset classes',
  ASSET_CLASSES: 'Asset classes',
  NUMBER_OF_DEALS: 'Number of deals in the last 3 years',
  KNOWLEDGE_AND_EXPERIENCE: 'Knowledge and experience',
  THE_CUSTOMER_HAS_TRAINED: 'The customer has been trained',
  THE_ADVISER_BELIEVES_CUSTOMER: 'The adviser believes that the customer has understood the risks of the product',
  SHARES_LISTED: 'Shares - Listed',
  SHARES_UNLISTED: 'Shares - Unlisted',
  FUNDS_UCITS: 'Funds - UCITS',
  FUNDS_AIF_SPECIALFONDER: 'Funds – AIF/Specialfonder',
  BONDS_LISTED: 'Bonds - Listed',
  BONDS_UNLISTED: 'Bonds - Unlisted',
  STOCK_INDEX_BONDS: 'Stock index bonds',
  HEDGE_FUNDS: 'Hedge funds',
  CREDIT_CERTIFICATE: 'Credit certificate',
  AUTOCALLS: 'Autocalls',
  PORTFOLIO_MANAGEMENT: 'Portfolio management',
  EXCHANGE_TRADED_FUNDS: 'ETF (Exchange Traded Funds)',
  DERIVATIVES_LEVERAGED: 'Derivatives and leveraged certificates',

  QUANTITY: 'Quantity',
  NONE: 'None',
  BASIC: 'Basic',
  GOOD_KNOWLEDGE: 'Good knowledge and experience',
  ADD_DESCRIPTIVE_TAGS: 'Add descriptive tags',
  SUSTAINABILITY: 'sustainability',
  LOCAL: 'local',
  NEXT_GENERATION: 'next generation',
  RISK_OF_LOSS: 'Risk of loss',
  THE_CLIENT_AWARE_THAT_FINANCIAL_INSTRUMENTS: 'The client is aware that financial instruments can increase and decrease in value  and there is a risk that the client loose money when investing',
  AGREEMENT_PAGE_HEADER: 'Agreement regarding investment advice and related services',
  THIS_AGREEMENT: 'This Agreement (\'Agreement\') has been entered into today between Citroneer AB (organization number 559159–1192 St Eriksgatan 63B, 112 34 Stockholm, e-mail address: kontakt@citroneer.com, hereinafter also referred to as \'Citroneer\') and the following customer (hereinafter also referred to \'The Customer\').',
  THE_ABOVE_CONTRACTUAL_PARTIES: 'The above contractual parties are hereinafter collectively referred to as the "Parties" and individually as "Party" or "Party". By "Agreement" is meant this Agreement together with Citroneer\'s Purchase Information (at www.citroneer.com/forkopsinformation) and Citroneer\'s General Terms and Conditions (at www.citroneer.com/general terms and conditions).',
  ABOUT_CITRONEER: 'About Citroneer',
  INFORMATION_ABOUT_CITRONEER: 'For information about Citroneer and its services, information about risks with investments in financial instruments, making complaints, best execution of orders and more, please refer to Citroneer\'s Purchase Information.',
  OPENING_OF_ACCOUNTS: 'Opening of accounts',
  CUSTOMER_INSTRUCTS_CITRONEER: 'The customer instructs Citroneer to open the following accounts with Citroneer with SEB as custodian bank.',
  CHOOSE_SERVICE: 'Choose service',
  INVESTMENT_ADVICE: 'Investment advice',
  CHARGES: 'Charges',
  ASSIGNMENTS_AND_FEES: 'Assignments and fees',
  ASSIGNMENTS_AND_FEES_TEXT: 'The customer assigns Citroneer to perform the services described in this Agreement as well as in Citroneer\'s General Terms and Conditions on an ongoing basis or at the Customer\'s request, hereinafter referred to as the "Assignment".',
  SERVICE: 'Service',
  CONSULTATION: 'Consultation',
  FEE_IN: 'Fee in %',
  TERMS_AND_CONDITIONS: 'Terms and Conditions',
  FOR_THIS_AGREEMENT_P1: 'For this Agreement, Citroneer\'s General Terms and Conditions (the terms) as published at each time apply',
  FOR_THIS_AGREEMENT_P2: 'Citroneer reserves the right to update its General Terms and Conditions at any time by notifying the Customer no later than 2 weeks before such an update takes place and from which date the new version of the General Terms and Conditions applies. If the Customer continues to use the licensed modules or services from Citroneer after this date, the Customer shall be deemed to have accepted the new version of Citroneer\'s General Terms and Conditions.',
  CONTRACT_PERIOD_TERMINATION: 'Contract period, termination',
  THIS_AGREEMENT_APPLIES: 'This Agreement applies from the date of signing and until further notice. A notice period of three (3) months applies thereafter for both Parties. Termination must be in writing.',
  LIMITATION_OF_LIABILITY: 'Limitation of liability',
  LIMITATION_OF_LIABILITY_P1: 'Citroneer is not responsible for the financial outcome of any investments that the Customer makes due to information or advice provided by Citroneer or for damage caused by the Customer providing incorrect or incomplete information to Citroneer. It is not the responsibility of Citroneer to consider the tax consequences of the Customer\'s transactions according to this Agreement. If there is an obstacle for Citroneer to fully or partially execute an order or other action that appears in this Agreement, the order or other action may be postponed until the obstacle has ended. Citroneer is not responsible if damage occurs to the Customer due to such delay. Citroneer is not responsible for damage caused by the customer order placed by Citroneer not being executed or only partially executed by Citroneer\'s counterparty or by the trading venue used by Citroneer or the counterparty. Citroneer is not responsible for damage that could have been avoided if complaints had been made immediately. Party is not responsible for damage caused by Swedish or foreign law, measures by Swedish or foreign authorities, war event, strike, blockade, boycott, lockout or other similar circumstance. The reservation regarding strikes, blockades, boycotts or lockouts applies even if the Party is subject to or itself takes such conflict measures. Damage that may arise in other cases shall be compensated by the Party only to the extent that the damage was caused by the Party being grossly negligent or it happened with intent.',
  LIMITATION_OF_LIABILITY_P2: 'In no case is the Party responsible for indirect damage or for damage caused by third parties.',
  APPLICABLE_LAW_AND_DISPUTE: 'Applicable Law and Dispute',
  APPLICABLE_LAW_AND_DISPUTE_P1: 'When interpreting this Agreement, Swedish law shall be applied. Disputes concerning the interpretation or application of this Agreement shall be settled by a Swedish court.',
  CUSTOMER_APPROVAL: 'Customer Approval',
  CUSTOMER_APPROVAL_P1: 'The customer has received information about Citroneer\'s Guidelines for best execution when receiving and forwarding orders through Citroneer\'s Purchase Information, which is available at www.citroneer.com/forkopsinformation. The Customer accepts Citroneer\'s Guidelines and agrees that Citroneer may participate in the execution of the Customer\'s order outside a regulated market or a trading platform. The customer agrees that changes to and additions to Citroneer\'s Pre-purchase Information are announced at',
  CUSTOMER_APPROVAL_P2: 'The customer further also agrees that Citroneer can update Citroneer\'s General Terms and Conditions by making changes and additions to them by publishing a new version at',
  CUSTOMER_APPROVAL_P3: 'no later than 30 days before such new version comes into force. If the Customer does not accept the new version, the Customer has the right to terminate the Agreement no later than the day before it enters into force. If the customer has not terminated the Agreement before the new version enters into force, the customer is considered to have accepted the new version of Citroneer\'s General Terms and Conditions.',
  COMPLETE_AGREEMENT_SIGNATURES: 'Complete agreement, signatures',
  COMPLETE_AGREEMENT_SIGNATURES_P1: 'This Agreement has been drawn up in one or more identical copies, in physical or digital form, and together Citroneer\'s Purchase Information, Citroneer\'s General Terms and any specifically stated annexes constitute the entire agreement and replace all previous agreements, agreements, etc. between the Parties.',
  SEND_APPROVAL: 'Send for approval',
  LOWLEVELTEXT: 'Be aware that the expected investment return most likely will be lower',
  HIGHTLEVELTEXT: 'Be aware that higher risk will incur higher volatility of your portfolio',
  WAITING_ADVISOR_CONFIRMATION: 'Waiting for advisor confirmation',
  CLIENT_AWAIT_APPROVE: 'Client await approve',
  COMPANY_NAME: 'Company name',
  ENTER_COMPANY_NAME: 'Enter company name',
  COMPANY_REPRESENTATIVE: 'Information about company representative',
  AUTHORITY: 'Authority',
  AUTHORITY_1: 'Beneficiar owner',
  AUTHORITY_2: 'Signatory',
  AUTHORITY_3: 'Proxy',
  PERSONAL_NUMBER: 'Personal number',
  // Know your company
  ABOUT_THE_COMPANY: 'Questions about the company',
  WHAT_COMPANY_FORM: 'What company form does the company have?',

  LIMITED_COMPANY: 'Limited company/Corporation',
  LIMITED_PARTNERSHIP: 'Limited partnership',
  SOLE_PROPRIETORSHIP: 'Sole proprietorship',
  PUBLIC_LIMITED_COMPANY: 'Public limited company in Sweden',
  STATE_REGION: 'State/Region/Municipality',
  ECONOMIC_ASSOCIATION: 'Economic association',
  NON_PROFIT_ASSOCIATION: 'Non-profit association',
  FOUNDATION_TRUST: 'Foundation/Trust',
  RELIGIOUS_COMMUNITY: 'Religious community',

  WHAT_INDUSTRY_DOES_THE_COMPANY: 'In what industry does the company operate?',
  CONSTRUCTION_INFRASTRUCTURE: 'Construction/Infrastructure',
  DATA_TECHNOLOGY_IT: 'Data/Technology/IT',
  OPERATION_MAINTENANCE: 'Operation/Maintenance',
  ECONOMICS_FINANCE: 'Economics/Finance',
  REAL_ESTATE: 'Real estate',
  RD_SCIENCE: 'R&D/Science',
  SALES_BUSINESS_DEVELOPMENT: 'Sales/Business development',
  HOTEL_RESTAURANT_TOURISM: 'Hotel/Restaurant/Tourism',
  HR_REKRYTERING: 'HR/Rekrytering',
  IMPORT_EXPORT_TRADE: 'Import/Export/Trade',
  LAW: 'Law',
  CUSTOMER_SUPPORT_SERVICE: 'Customer support/service',
  AGRICULTURE_FORESTRY: 'Agriculture/Forestry',
  MANAGEMENT: 'Management',
  LOGISTICS_TRANSPORTATION: 'Logistics/Transportation',
  MARKETING_ADVERTISING: 'Marketing/Advertising',
  HEALTHCARE: 'Healthcare',
  WRITER_PUBLISHING: 'Writer/Publishing',
  SECURITY_RESCUE_SERVICE: 'Security/Rescue service',
  THEOLOGY_PSYCHOLOGY_PHILOSOPHY: 'Theology/Psychology/Philosophy',
  MANUFACTURING_PRODUCTION: 'Manufacturing/Production',
  EDUCATION: 'Education',

  COUNTRY_COMPANY_TAX: 'In which country does the company have its tax domicile?',
  COMPANY_FINANCIAL_INSTITUTION: 'Is the company a financial institution?',
  IS_COMPANY_ACTIVE_PASSIVE: 'Is the company active or passive?',
  ACTIVE: 'Active',
  ACTIVATE: 'Activate',
  PASSIVE: 'Passive',
  HOW_MANY_EMPLOYEES: 'How many employees does the company have?',
  TURNOVER_IN_THE_COMPANY: 'What is the turnover in the company (net)?',

  OVER_100: 'Over 100',
  NEWLY_STARTED_COMPANY: 'Newly started company',
  LESS_THAN_3_MILLION: 'Less than 3 million SEK',
  MILLION_3_40: '3-40 million SEK',
  OVER_40_MILLION: 'Over 40 million SEK',

  WHERE_DOES_THE_COMPANYS_MONEY: 'Where does the company\'s money and / or securities that you deposit in Citroneer come from?',
  PROFIT_FROM_BUSINESS: 'Profit from business',
  COMPANY_SALES: 'Company sales',
  SHARE_CAPITAL: 'Share capital',
  INVESTMENTS: 'Investments',
  MEMBERSHIP_FEES: 'Membership fees',
  DONATIONS: 'Donations',
  ALLOWANCE_2: 'Allowance',
  COMPANY_HAVE_AUDITOR: 'Does the company have an auditor?',
  OWNERSHIP_STRUCTURE: 'Ownership structure',
  ANYONE_WHO_CONTROLS_MORE: 'Is there anyone who controls more than 25% of the votes in the company?',
  ANYONE_ELSE_WHO_CONTROLS_MORE: 'Is there anyone else who controls more than 25% of the votes in the company?',
  TYPE_OF_OWNER: 'What type of owner is it?',
  INDIVIDUAL: 'Private individual',
  COMPANY: 'Company',
  MISSING_SWEDISH_NUMBER: 'Missing Swedish personal number',
  DATE_OF_BIRTH: 'Date of birth',
  PERCENTAGE: 'Percentage',
  PERCENTAGE_OF_VOTES: 'Percentage of votes',
  COUNTRY_PERSON_HAVE_CITIZENSHIP: 'In which country does the person have citizenship?',
  COUNTRY_PERSON_TAX_RESIDENT: 'In which country is the person tax resident?',
  TIN: 'TIN',
  POLITICALLY_EXPOSED_POSITION_PERSON: 'Is the person or has the person been a person in a politically exposed position or a family member or a known employee of such a person?',
  MISSING_ORGANIZATION_NUMBER: 'Missing Swedish organization number',
  ORGANIZATION_NUMBER_OWNING: 'Organization number of the owning company',
  FOREIGN_ORGANIZATION_NUMBER: 'Foreign organization number for the owning company',
  NAME_OWNING_COMPANY: 'Name of the owning company',
  ADDRESS_OWNING_COMPANY: 'Postal address for the owning company',
  ZIPCODE_OWNING_COMPANY: 'Post code for the owning company',
  CITY_OWNING_COMPANY: 'Postal city for the owning company',
  COUNTRY_OWNING_COMPANY: 'Country for the owning company',
  TAX_COUNTRY_OWNING_COMPANY: 'Tax domicile for the owning company',
  PERCENTAGE_OF_VOTES_COMPANY: 'Percentage of votes for the owning company',
  ANYONE_WHO_CONTROLS_COMPANY: 'Is there anyone who controls more than 25% of the votes in the owning company?',
  NEED_DOWNLOAD_AND_FILL: 'You need to download and fill in a form with the complete ownership structure.',
  ANYONE_ELSE_WHO_CONTROLS: 'Is there anyone else who controls more than 25% of the votes in the owning company?',
  CO_OWNERS_WHO_ARE_RELATED: 'Are there co-owners who are related to the above-mentioned owners?',
  HOW_MANY_CO_OWNERS_OWNERS: 'How many co-owners are related to the above-mentioned owners?',
  MORE_THAN_4: 'More than 4',
  ARE_THERE_TWO_OR_MORE_PARTIES: 'Are there two or more related parties who jointly control more than 25% of the votes in the company?',
  HOW_MANY_PEOPLE_CONTROL_VOTES: 'How many people stand together for the control of the votes?',
  ROLE_FUNCTION: 'The person\'s role/function in your company',
  ROLE_FUNCTION2: 'role/function',
  CLICK_UPLOAD: 'Click to Upload',
  UPLOAD_DOCUMENT: 'Upload a document that proves the person is signatory or have a proxy.',
  UPLOAD_ID_DOCUMENT: 'Uploaded copy of ID - document',
  FILTER: 'Filter',
  SEARCH_NAME: 'Search Name or ISIN',
  SEARCH_CODE: 'Search Name or code',
  ACTIONS: 'Actions',
  CREATE_NEW_INSTRUMENT: 'Create New Instrument',
  INSTRUMENT_CREATION: 'Instrument creation',
  INSTRUMENT_EDIT: 'Edit Instrument',
  REQUEST_EDIT: 'Edit Instrument Request',
  HIGHT: 'Hight',
  MEDIUM: 'Medium',
  APPLY: 'Apply',
  STATUS: 'Status',
  RE_INVITE: 'Re-invite',
  //
  MODEL: 'Model',
  APPROVED_INSTRUMENTS: 'Approved Instruments',
  RECOMMENDATION: 'Recommendation',
  MAPS: 'Maps',
  MAP: 'Map',
  INVESTOR_BIAS: 'Investor Bias',
  INSTRUMENT_MAPPING: 'Instrument Mapping',
  DEFINED_ASSET_CLASSES: 'Defined Asset Classes',
  SUGGESTED_PORTFOLIOS: 'Suggested Portfolios',
  SUGGESTIONS: 'Suggestions',
  APPROVED: 'Approved',
  EXECUTING: 'Executing',
  EXECUTE: 'Execute',
  IMPLEMENTED: 'Implemented',
  APPROVAL_DATE: 'Approval Date',
  DELETE_THIS_SUGGESTED: 'Do you really want to delete this suggested portfolio?',
  CONFIRM_APPROVAL: 'Confirm approval',
  WANT_TO_APPROVE: 'Do you really want to approve this suggested portfolio?',
  PORTFOLIOS_REPORT: 'Portfolio\'s Report',
  DELETE_THIS_APPROVED: 'Do you really want to delete this approved portfolio?',
  PORTFOLIO_NAME: 'Portfolio name',
  QUANTIFY: 'Quantify',
  CONFIRM_EXECUTION: 'Confirm execution',
  CONFIRM_IMPLEMENTE: 'Confirm implement',
  IMPLEMENTE: 'Implement',
  WANT_ORDER_EXECUTED: 'Do you want this order to be quantified?',
  WANT_ORDER_IMPLEMENTE: 'Do you want this order to be implemented?',
  EXECUTION_DATE: 'Quantified Date',
  IMPLEMENTATION_DATE: 'Implementation Date',
  EXECUTED_IN_MARKET: 'Executed in Market',
  CURRENT_MODEL: 'Current Model',
  CREATE_MODEL: 'Create model',
  NEW_MODEL: 'New Model',
  INDEX_SERIES: 'Index Series',
  MODEL_PRIMITIVES: 'Model primitives',
  RETURN_SERIES: 'Return Series',
  RETURN_ASSUMPTIONS: 'Return Assumptions',
  UPLOAD_FROM_EXEL: 'Upload from Excel',
  BACK_TO_SETTINGS: 'Back to Settings',
  BACK_TO_INDEX_SERIES: 'Back to Index Series',
  SELECT_ASSET_CLASS: 'Select Asset Class',
  RECENT_MODELS: 'Recent Models',
  MAKE_ACTIVE: 'Make active',
  ADD_MODEL: 'Add model',
  PROVIDE_MODEL_NAME: 'Please provide model name',
  CLONE_MODEL: 'Clone model',
  SELECT_AN_OPTION: 'Select an option',
  MEAN_LINEAR_RETURN: 'Mean Linear return',
  MEAN_LOG_RETURN: 'Mean Log return',
  VARIANCE: 'Variance',
  STANDARD_DEV_VOLATILITY: 'Standard Dev (Volatility)',
  WEIGHT_RANGE_VOLATILITY_ADJUSTMENT: 'Weight range volatility adjustment',
  QUANT_VALUE: 'Quant Value',
  OVERRIDE: 'Override',
  CLEAR_ALL: 'Clear all',
  MODAL_DELETE_MODAL: 'Are you sure you want to delete the model:',
  LABELS: 'Labels',
  LABEL_NAME: 'Label Name',
  SUM: 'Sum',
  MODAL_DELETE_LABEL: 'Are you sure you want to delete label',
  RATIO_CONSTRAINTS: 'Ratio Constraints',
  COMPARISON: 'Comparison',
  LABEL: 'Label',
  SIMPLE_CONSTRAINTS: 'Simple Constraints',
  CONFIRM_ACTIVATION: 'Confirm activation',
  MODAL_ACTIVATE_MODAL: 'Are you sure you want to set the model as default:',
  ASSET_CLASS_SETS: 'Asset class sets',
  ACTIVE_MAPS: 'Active Maps',
  DRAFT_MAPS: 'Draft Maps',
  CREATE_MAP: 'Create Map',
  EDIT_MAP: 'Edit Map',
  MAP_CREATION: 'Map Creation',
  APPROVE_INSTRUMENTS: 'Approve Instruments',
  SELECT_ALL: 'Select All',
  RECOMMENDED: 'Recommended',
  ACCOUNT_TYPE: 'Account type',
  PRIVATE: 'Private',
  CORPORATE: 'Corporate',
  COMPANY_NUMBER: 'Company number',
  RELATED_PERSONS: 'Related persons',
  ACCOUNT_OWNER: 'Account owner',
  ACCOUNT: 'Account',
  ADD_PERSON: 'Add person',
  INVITE_CLIENT: 'Invite client',
  PERMISSION: 'Permission',
  READ: 'Read only',
  WRITE: 'Write',
  IS_OWNER: 'is Owner',
  RELATED_ADVISORS: 'Related advisors',
  OFF: 'Off',
  ON: 'On',
  ACCOUNT_DETAILS: 'Account Details',
  SPLIT: 'Split',
  SELECT_ACCOUNT: 'Select Account',
  EDIT_ACCOUNT_DETAILS: 'Edit account details',
  CREATE_AND_INVITE_CLIENT: 'Create and invite client',
  REGISTRATION_CODE: 'Registration code',
  STANDINGS: 'Standings',
  CLASS: 'Class',
  QUANTIFIED: 'Quantified',
  CONFIRM_QUANTIFICATION: 'Confirm quantification',
  CONFIRM_DOWNLOUD_REPORT: 'Confirm download report',
  SELECT_FILE_FORMAT_FOR_DOWNLOAD: 'Select a file format for the download',
  SELL_ALL: 'Sell all',
  PRICE_DATE: 'Update time',
  SIGN_UP_NOTE_CLIENT: 'Please ensure you have the registration code provided via email or by a family member already using the platform.',
  SIGN_UP_NOTE_ADVISOR: 'Your application will be reviewed by the Admin, and you’ll receive an email once your access is confirmed.',
  INVESTMENT_VEHICLE_TYPE: 'Type',
  // INVESTMENT_VEHICLE_TYPE: 'Investment Vehicle Type',
  MODAL_DELETE_ACCOUNT: 'Are you sure you want to delete the account:',
  DELETE_ACCOUNT: 'Delete account',
  ETF: 'ETF',
  CERT: 'Cert',
  FUND: 'Fund',
  EQUITY: 'Equity',
  UNKNOWN: 'Unknown',
  MAKE_DEFAULT: 'Make default',
  VIEW: 'View',
  CHANGE_INFO: 'Change info',
  CONFIRM_CHANGE_INFO: 'Confirm change info',
  SELECT_STATUS: 'Select status:',
  DEVELOPMENT: 'Development',
  STAGING: 'Staging',
  PRODUCTION: 'Production',
  SUCCEEDED: 'Succeeded',
  DELETED: 'Deleted',
  USERS: 'Users',
  USERS_LIST: 'Users list',
  ROLE_HEADER: 'Role',
  SUPERADMIN_ROLE: 'SuperAdmin',
  ADVISER_ROLE: 'Adviser',
  CLIENT_ROLE: 'Client',
  INVITE_USER: 'Invite User',
  ALL: 'All',
  DEACTIVE: 'Not active',
  AWAIT_APPROVE: 'Awaiting approval',
  CLIENT_NOT_SECURE: 'Client not secure',
  ADVISER_NOT_SECURE: 'Adviser not secure',
  SUPERADMIN_NOT_SECURE: 'Superadmin not secure',
  EDIT_USER: 'Edit User',
  EDIT_USER_INFORMATION: 'Edit User information',
  USER_INVITATION: 'User invitation',
  USER_ROLE: 'User Role',
  IDENTIFICATION_NUMBER: 'Identification number',
  RESET_PASSWORD_CODE_SENT: 'Reset password code sent',
  USER_STATUS_VERIFY_EMAIL: 'User status verify email',
  CLEAR_IN: 'Clear identification number',
  CONFIRM_CLEAR_IN: 'Confirm clear identification number',
  CLEAR_TEXT: 'After clearing a personal number, only the owner of the number can add it using BankID',
};
