import { decimalSeparator } from './decimalSeparator';

export const numberWithSpaces = (x) => {
  return x || x === 0 ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')?.replace('.', decimalSeparator()) : '';
};

export const numberWithSpacesInter = (x, dig = null) => {
  const dicim = decimalSeparator();

  if (x || x === 0) {
    let [integerPart, fractionalPart] = x.toString().split('.');

    if (dig !== null && fractionalPart) {
      fractionalPart = fractionalPart.substring(0, dig);
    }

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, dicim === ',' ? ' ' : ',');

    return fractionalPart ? `${integerPart}${dicim}${fractionalPart}` : integerPart;
  }

  return '';
};

