import { ACTIVATE_ADVISOR, CREATE_ADVISOR, DELETE_ADVISOR, GET_ADVISORS, GET_ADVISORS_AWAIT_APPROVE, GET_USERS_FOR_ADVISOR } from './advisorActionTypes';
import { RESET_ERROR } from '../helpers/auxiliaryActionTypes';
import { LOG_OUT } from '../user/userActionTypes';

const initialState = {
  users: { list: null, totalCount: 0 },
  advisorsAwaitApprove: {},
  isLoading: false,
  isError: false,
  availableUsers: null,
};

export const advisorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADVISORS.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_ADVISORS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload
      };

    case GET_ADVISORS.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case GET_USERS_FOR_ADVISOR.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_USERS_FOR_ADVISOR.SUCCESS:
      return {
        ...state,
        isLoading: false,
        availableUsers: action.payload
      };

    case GET_USERS_FOR_ADVISOR.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case GET_ADVISORS_AWAIT_APPROVE.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_ADVISORS_AWAIT_APPROVE.SUCCESS:
      return {
        ...state,
        advisorsAwaitApprove: action.payload,
        isLoading: false,
        isError: false
      };

    case GET_ADVISORS_AWAIT_APPROVE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case ACTIVATE_ADVISOR.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ACTIVATE_ADVISOR.SUCCESS: {
      const advisorsApprove = state.advisorsAwaitApprove?.list || [];
      const newAdvisorList = advisorsApprove?.filter(el => el.ID !== action.payload);
      return {
        ...state,
        advisorsAwaitApprove: { ...state.advisorsAwaitApprove, list: newAdvisorList },
        isLoading: false,
        isError: false
      };
    }

    case ACTIVATE_ADVISOR.ERROR:
      return {
        ...state,
        isLoading: true,
        isError: action.payload
      };

    case CREATE_ADVISOR.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case CREATE_ADVISOR.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false
      };

    case CREATE_ADVISOR.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case DELETE_ADVISOR.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case DELETE_ADVISOR.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false
      };

    case DELETE_ADVISOR.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case RESET_ERROR:
      return {
        ...state,
        isError: false
      };

    case LOG_OUT:
      return {
        users: {},
        advisorsAwaitApprove: {},
        isLoading: false,
        isError: false
      };

    default: {
      return state;
    }
  }
};
